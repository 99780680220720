import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { login, logout } from "../services/auth";
import { setAdmin, setUserSession } from "../auth";
const user = JSON.parse(localStorage.getItem("user"));

export const userLogin = createAsyncThunk(
  "auth/login",
  async ({ user_name, hash_password }, thunkAPI) => {
    try {
      const requestBody = JSON.parse(
        JSON.stringify({ user_name, hash_password })
      );
      const data = await login(requestBody);

      setUserSession(data.result.token);
      setAdmin(data.result.is_super_admin);

      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const userLogout = createAsyncThunk("auth/logout", async() => {
 await logout()
});
const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };
const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [userLogin.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [userLogin.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [userLogout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});
const { reducer } = authSlice;
export default reducer;
