/* eslint-disable react-hooks/exhaustive-deps */
import Base from "../Base";
import { Row, Typography } from "antd";
import { authHeaders, setpage } from "../../auth";
import { AxiosConfig } from "../../APIConfig";
import { useEffect, useState } from "react";
import moment from "moment";
import AwardReceiptsTable from "./AwardReceiptsTable";

const Awarded = () => {
  const { Title, Text } = Typography;

  const [awardedData, setAwardedData] = useState([]);

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [loading, setLoading] = useState(false);

  const getFlaggedReceipt = () => {
    setLoading(true);
    AxiosConfig.get(`/receipts?is_flagged=0&status_id=0`, {
      headers: authHeaders(),
    })
      .then((res) => {
        if (res.data.status === "success") {
          const temp = res.data.result.data.map((v) => {
            return {
              ...v,
              receipt_number: v.receipt_number ?? "-",
              created_at: moment(v.created_at).format("DD-MM-YYYY"),
              customer_external_id: v.customer_external_id ?? "-",
              receipt_txn_date: !v.receipt_txn_date
                ? "No Date"
                : v.receipt_txn_date,
              ocr_confident_level: v.ocr_confident_level ?? 0,
              remarks: v.remarks ?? "-",
            };
          });
          setAwardedData(temp);
          setLoading(false);
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              total: res.data.result.pagination.total_count,
            },
          });
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    setpage("/awarded");
    getFlaggedReceipt();
  }, [JSON.stringify(tableParams)]);

  return (
    <Base menuKey="4">
      <Row style={{ marginTop: 20 }}>
        <Title level={3}>
          <Text type="secondary">Awarded Receipts</Text>
        </Title>
      </Row>

      <AwardReceiptsTable
        data={awardedData}
        tableParams={tableParams}
        setTableParams={setTableParams}
        loading={loading}
      />
    </Base>
  );
};

export default Awarded;
