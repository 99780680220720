import axios from "axios";


axios.defaults.headers.common['app-secrets'] = `${process.env.REACT_APP_BIRA_APP_SECRET}`;
axios.defaults.headers.common['x-api-key'] = `${process.env.REACT_APP_X_API_KEY}`


const ApiConfig2 = axios.create({
  baseURL: `${process.env.REACT_APP_BIRA_API_BASE_URL_V2}`,
});


ApiConfig2.interceptors.request.use(
  (config) => {
    localStorage.getItem("authToken") && (config.headers.authorization = localStorage.getItem("authToken"));
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default ApiConfig2;