import { Table } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useEffect, useState } from "react";
import { AxiosConfig } from "../../../APIConfig";
import { authHeaders, getrnum } from "../../../auth";

export const ReceiptLogs = (props) => {
  const rnum = getrnum();

  const columns=[
    {
      title: "Old Status",
      key: "prev_status",
      dataIndex:"prev_status"
    },

    {
        title:"New Status",
        key: "new_status",
        dataIndex:"new_status"
    },
    {
        title: "Date of Change",
      key: "created_at",
      dataIndex:"created_at",
    render:(item)=>{
      return <Text>{item.substring(11,16)+', '+  item.substring(0,10)}</Text>
    }
  
     
    },
    {
        title: "Change By",
      key: "change_by",
      dataIndex:"change_by"

    },
    {
        title: "Notes",
      key: "notes",
      dataIndex:"notes"
  
    },
  ]
  const [receiptLogs, setReceiptLogs] = useState([]);
  useEffect(() => {
    const url = "/receiptLogs?receipt_id=" + rnum;
    AxiosConfig.get(url, { headers: authHeaders() })
      .then((res) => {
        if (res.data.status === "success") {
            const rLogs=[]
            res.data.result.data.forEach((item)=>{
                let obj={}
                obj.prev_status=item.prev_status
                obj.new_status=item.new_status
                obj.created_at=`${item.created_at}`
                console.log(JSON.stringify(obj.created_at))
                obj.notes=item.notes
                obj.change_by=item.admin_user?.first_name+' '+item.admin_user?.last_name
                rLogs.push(obj)
            })
       setReceiptLogs(rLogs)
          console.log(rLogs)
        } else {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [rnum]);
  return <Table columns={columns} dataSource={receiptLogs}></Table>;
};
