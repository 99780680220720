import {
  Button,
  Card,
  Col,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";
import {  removeSaveButtonClicked, setdetail, setstore } from "../../../auth";
import Text from "antd/lib/typography/Text";
import React, { useEffect, useState } from "react";
export const RecieptContents = (props) => {
  const { store_details, processing_master_status } = props.contents;

  //contains all the store details required for the reciept details box
  const recieptContent = props.contents;

  const [storeData, setStoreData] = useState({
    store__name: "",
    receipt_number: "",
    receipt_txn_date: "",
    total_bill_amount: "",
    gst_number: "",
    remarks: "",
    store: [],
    taproom_bill: "no",
    tapRoomBill: "",
  });
  const [, setoriginal] = useState([]);
  console.log("store data after change:", JSON.stringify(storeData));

  const optionBoolean = [
    {
      value: "yes",
      label: "yes",
    },
    {
      value: "no",
      label: "no",
    },
  ];

  const optionRemarks = [
    {
      value: "Store Name is Missing",
      label: "Store Name is Missing",
    },
    {
      value: "Details like Store Name, GST etc. are missing",
      label: "Details like Store Name, GST etc. are missing",
    },
    {
      value: "GST Number is Missing",
      label: "GST No. is Missing",
    },
    {
      value: "Not a valid receipt",
      label: "Not a valid receipt",
    },
    {
      value: "Duplicate Scan",
      label: "Duplicate Scan",
    },
    {
      value: "Processed Successfully",
      label: "Processed Successfully",
    },
  ];

  useEffect(() => {
    var obj = {};
   
    window.onbeforeunload = function() {
      removeSaveButtonClicked()
  };
    obj["receipt_number"] = recieptContent.receipt_number
    obj["gst_number"] =
      recieptContent.store_details === null || undefined
        ? ""
        : recieptContent.store_details?.gst_number;
    obj["remarks"] =
      recieptContent.remarks === null || undefined
        ? ""
        : recieptContent.remarks;
    obj["receipt_txn_date"] =
      recieptContent.receipt_txn_date !== null
        ? recieptContent.receipt_txn_date
        : "dd/mm/yyyy";
    obj["total_bill_amount"] = recieptContent.total_bill_amount;
    obj["store_name"] =
      recieptContent.store_details === null || undefined
        ? ""
        : recieptContent.store_details?.store_name;
    obj["taproom_bill"] =
      recieptContent.taproom_bill && recieptContent.taproom_bill !== undefined
        ? recieptContent.taproom_bill === "yes"
          ? "yes"
          : recieptContent.taproom_bill === "no"
          ? "no"
          : ""
        : "";

    setStoreData(obj);
    setstore(obj);
    setoriginal(obj);
    setdetail(false);
  }, [
    props,
    recieptContent.receipt_number,
    recieptContent.receipt_txn_date,
    recieptContent.remarks,
    recieptContent.store_details,
    recieptContent.taproom_bill,
    recieptContent.total_bill_amount,
  ]);

  return (
    <Card>
      <Col>
        {/*Row 1: Heading and Reciept status*/}
        <Row
          style={{
            justifyContent: "space-between",
            marginBottom: 20,
            paddingLeft: 8,
            paddingRight: 8,
          }}
        >
          <Col style={{ fontSize: 15, textTransform: "uppercase" }}>
            Reciept Details
          </Col>
          <Button disabled ghost style={{ color: "black" }}>
            {processing_master_status ? processing_master_status.status : "---"}
          </Button>
        </Row>

        {/*Row 2: Capillary Id, Points Awarded , OCR confidence level*/}
        <Row
          style={{
            justifyContent: "space-between",
            backgroundColor: "lightgray",
            paddingTop: 10,
            paddingRight: 10,
            paddingLeft: 10,
            paddingBottom: 10,
          }}
        >
          <Col>
            <Row
              style={{
                fontSize: 15,
                textTransform: "capitalize",
                marginBottom: 10,
              }}
            >
              Capillary Transaction ID:
            </Row>
            <Row>
              {" "}
              {recieptContent.txn_ref_no && recieptContent
                ? recieptContent.txn_ref_no
                : "---"}
            </Row>
          </Col>
          <Col>
            <Row
              style={{
                fontSize: 15,
                textTransform: "capitalize",
                marginBottom: 10,
              }}
            >
              Points Awarded:
            </Row>
            <Row style={{ marginLeft: 10 }}>
              {recieptContent.points_awarded && recieptContent
                ? recieptContent.points_awarded
                : "---"}
            </Row>
          </Col>
          <Col>
            <Row
              style={{
                fontSize: 15,
                textTransform: "capitalize",
                marginBottom: 10,
              }}
            >
              OCR Confidence level:
            </Row>
            <Row style={{ marginLeft: 10 }}>
              {recieptContent.ocr_confident_level && recieptContent
                ? recieptContent.ocr_confident_level.toFixed(2) + "%"
                : "---"}
            </Row>
          </Col>
        </Row>

        {/* Row 3 : Text Inputs and Selectors*/}
        <Row
          style={{
            justifyContent: "space-between",
            paddingTop: 10,
            paddingRight: 10,
            paddingLeft: 10,
            paddingBottom: 10,
          }}
        >
          <Col>
            {/* Liquor store Name */}
            <Row style={{ marginBottom: 10 }}>
              <Text style={{ textTransform: "capitalize" }}>Store Name</Text>
              <Input
                onChange={(event) => {
                  setStoreData({
                    ...storeData,
                    store_name: event.target.value,
                  });
                  const obj = storeData;
                  obj.store_name = event.target.value;
                  setstore(obj);
                }}
                defaultValue={
                  store_details
                    ? store_details.store_name
                      ? store_details.store_name
                      : ""
                    : ""
                }
              ></Input>
            </Row>
            {/* Reciept Number */}
            <Row style={{ marginBottom: 10 }}>
              <Text style={{ textTransform: "capitalize", marginBottom: 5 }}>
                Reciept Number
              </Text>
              <Input
                onChange={(event) => {
                  setStoreData({
                    ...storeData,
                    receipt_number: event.target.value,
                  });
                  const obj = storeData;
                  obj.receipt_number = event.target.value;
                  setstore(obj);
                }}
                defaultValue={
                  recieptContent.receipt_number
                    ? recieptContent.receipt_number
                    : ""
                }
              ></Input>
            </Row>

            {/* GST Number */}
            <Row
              style={{
                marginBottom: 10,
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Text style={{ textTransform: "capitalize", marginBottom: 5 }}>
                GST Number
              </Text>
              <Input
                defaultValue={
                  store_details && store_details.gst_number
                    ? store_details.gst_number
                    : ""
                }
                onChange={(event) => {
                  setStoreData({
                    ...storeData,
                    gst_number: event.target.value,
                  });
                  const obj = storeData;
                  obj.gst_number = event.target.value;
                  setstore(obj);
                }}
              ></Input>
            </Row>
            {/* Taproom Bill */}
            <Row
              style={{
                flexDirection: "column",
                justifyContent: "flex-start",
                marginBottom: 10,
              }}
            >
              <Text style={{ textTransform: "capitalize", marginBottom: 5 }}>
                Taproom Bill
              </Text>
              <Select
                onChange={(value) => {
                  setStoreData({ ...storeData, taproom_bill: value });
                  const obj = storeData;
                  obj.taproom_bill = value;
                  setstore(obj);
                }}
                defaultValue={
                  recieptContent.taproom_bill ? recieptContent.taproom_bill : ""
                }
                options={optionBoolean}
              />
            </Row>
          </Col>

          <Col>
            {/* Issued date of the  Reciept  */}
            <Row
              style={{
                marginBottom: 10,
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Text style={{ textTransform: "capitalize" }}>Date</Text>
              <Input
              
              onChange={(event) => {
                setStoreData({
                  ...storeData,
                  receipt_txn_date: event.target.value,
                });
                const obj = storeData;
                obj.receipt_txn_date = event.target.value;
                setstore(obj);
              }}
              defaultValue={
                  recieptContent && recieptContent.receipt_txn_date
                    ? recieptContent.receipt_txn_date
                    :  "dd/mm/yyyy"
                }/>

            </Row>
            {/* Total Reciept Value */}
            <Row
              style={{
                marginBottom: 10,
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Text style={{ textTransform: "capitalize", marginBottom: 5 }}>
                Total Reciept Value
              </Text>
              <InputNumber
                addonBefore="₹"
                pattern={"[+-]?d+(?:[.,]d+)"}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                onChange={(val) => {
                  setStoreData({ ...storeData, total_bill_amount: val });
                  const obj = storeData;
                  obj.total_bill_amount = val;
                  setstore(obj);
                }}
                defaultValue={
                  recieptContent && recieptContent.total_bill_amount
                    ? recieptContent.total_bill_amount
                    : 0
                }
              ></InputNumber>
            </Row>

            <Row
              style={{
                marginBottom: 10,
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Text style={{ textTransform: "capitalize", marginBottom: 5 }}>
                Remarks
              </Text>
              <Select
              style={{width:305}}


                onChange={(value) => {
                  setStoreData({ ...storeData, remarks: value });
                  const obj = storeData;
                  obj.remarks = value;
                  setstore(obj);
                }}
                defaultValue={{
                  label:
                    recieptContent && recieptContent.remarks
                      ? recieptContent.remarks
                      : null,
                  value:
                    recieptContent && recieptContent.remarks
                      ? recieptContent.remarks
                      : null,
                }}
                options={optionRemarks}
              />
            </Row>
          </Col>
        </Row>
      </Col>
    </Card>
  );
};
