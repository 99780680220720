import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Modal, Row, Select } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";
import { AxiosConfig } from "../../../APIConfig";
import { authHeaders } from "../../../auth";
const { Option } = Select;
export const AddLocationModal = (props) => {
  //   const [initialContent, setInitalContent] = useState({ id: 0, is_active: 0 });
  const [form] = Form.useForm();

  const geoOptions = [
    {
      value: "country",
    },
    {
      value: "state",
    },
    {
      value: "city",
    },
  ];

  const handleSubmit = (val) => {
    if (
      val.name &&
      val.location_type &&
      val.location_type !== undefined &&
      val.name !== undefined
    ) {
      let obj = JSON.parse(JSON.stringify(val));
      console.log("Add location to be saved", obj);
      let url = `/vouchers/location/add`;
      AxiosConfig.post(url, obj, { headers: authHeaders() })
        .then((res) => {
          if (res.data.status === "success") {
            console.log("add category response", JSON.stringify(res));
          }

          onAddCancel();
          window.location.reload();
        })
        .catch(function (error) {
          console.log(error, "error");
          onAddCancel();
        });
    }
  };

  const onAddCancel = () => {
    form.resetFields();
    props.onCancel();
  };
  const onAddOk = () => {
    props.onOk();
  };
  return (
    <Modal
      onOk={onAddOk}
      onCancel={onAddCancel}
      visible={props.visible}
      closable={false}
      maskClosable={false}
      footer={null}
    >
      <Row style={{ justifyContent: "space-between" }}>
        <Text style={{ fontFamily: "sans-serif", fontSize: 20 }}>
          Add Location
        </Text>
        <CloseCircleOutlined style={{ fontSize: 18 }} onClick={onAddCancel} />
      </Row>
      <Divider />
      <Form form={form} layout="horizontal" onFinish={handleSubmit}>
        <Form.Item name="name" label="Name">
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item name="location_type" label="Type">
          <Select>
            {geoOptions.map((item) => {
              return <Option value={item.value}>{item.value}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item name="alias" label="Alias">
          <Input autoComplete="off" />
        </Form.Item>

        <Form.Item>
          <Row style={{ justifyContent: "space-evenly" }}>
            <Button type="primary" htmlType="submit">
              SAVE
            </Button>

            <Button type="primary" onClick={onAddCancel}>
              Cancel
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};
