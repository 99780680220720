import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Modal, Row, Select } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";
import { AxiosConfig } from "../../../APIConfig";
import { authHeaders } from "../../../auth";

export const AddUser = (props) => {
  const [form] = Form.useForm();

  form.setFieldsValue({
    user_name: "",
    password: "",
  });

  const onEditCancel = () => {
    form.resetFields();
    props.onCancel();
  };
  const onEditOk = () => {
    props.onCancel();
  };
  const handleSubmit = (val) => {
    const obj = JSON.parse(JSON.stringify(val));

    if (
      obj.user_name !== "" &&
      obj.password !== "" &&
      Number.isInteger(obj.roll_id)
    ) {
      if (obj.roll_id < 3) {
        const url = "/users/add";
        AxiosConfig.post(url, obj, { headers: authHeaders() })
          .then((res) => {
            if (res.data.status === "success") {
              console.log("add User response", res);
              window.location.reload();
            }
          })
          .catch(function (error) {
            if(error?.response?.data?.message){
              alert(error?.response?.data?.message)
            }
          });
      }
    }
  };

  const roleOptions = [
    {
      label: "Super Admin",
      value: 1,
    },
    {
      label: "Admin",
      value: 2,
    },
  ];

  return (
    <Modal
      onOk={onEditOk}
      onCancel={onEditCancel}
      visible={props.visible}
      closable={false}
      maskClosable={false}
      footer={null}
    >
      <Row style={{ justifyContent: "space-between" }}>
        <Text style={{ fontFamily: "sans-serif", fontSize: 20 }}>Add User</Text>
        <CloseCircleOutlined style={{ fontSize: 18 }} onClick={onEditCancel} />
      </Row>
      <Divider />
      <Form form={form} layout="horizontal" onFinish={handleSubmit}>
        <Form.Item
          name="user_name"
          label="User Name"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
          ]}
        >
          <Input
            placeholder={"Enter a valid Email address"}
            autoComplete="off"
            style={{ width: 350 }}
          />
        </Form.Item>

        <Form.Item name="password" label="Password">
          <Input.Password
            placeholder="input password"
            style={{ width: 350, left: 10 }}
          />
        </Form.Item>

        <Form.Item name="roll_id" label="Role">
          <Select options={roleOptions} style={{ width: 350, left: 44 }} />
        </Form.Item>

        <Form.Item>
          <Row style={{ justifyContent: "space-evenly" }}>
            <Button type="primary" htmlType="submit">
              SAVE
            </Button>

            <Button type="primary" onClick={onEditCancel}>
              Cancel
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};
