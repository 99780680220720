import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Table, Tag, Typography } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AxiosConfig } from "../../APIConfig";
import { authHeaders } from "../../auth";
import Base from "../Base";
import { AddGeographyModal } from "./Modals/AddGeographyModal";
import { EditGeographyModal } from "./Modals/EditGeographyModal";

export const Geography = () => {
  const { Title, Text } = Typography;
  const [editContent, setEditContent] = useState({});
  const [, setLoading] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const [locationOptions, setLocationOptions] = useState([]);
  const [geographies, setGeographies] = useState([]);

  const [filterData, setFilterData] = useState([]);
  const [nameSearch, setNameSearch] = useState("");

  useEffect(() => {
    if (geographies) {
      const results = geographies?.filter(
        (item) =>
          item.title.toLowerCase().includes(nameSearch.toLowerCase()) ||
          item.geography_type.toLowerCase().includes(nameSearch.toLowerCase())
      );
      setFilterData(results);
    }
  }, [nameSearch, geographies]);

  const handleDelete = (record) => {
    let obj = {};
    obj.id = record.id;
    console.log("record delete", obj);
    let url = `/vouchers/geography/delete`;
    setLoading(true);
    AxiosConfig.delete(url, { headers: authHeaders(), data: obj })
      .then((res) => {
        console.log("delete geography response", JSON.stringify(res));
        setLoading(false);
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error, "error");
        setLoading(false);
      });
  };

  useEffect(() => {
    const url = "/vouchers/geography";
    AxiosConfig.get(url, { headers: authHeaders() })
      .then((res) => {
        if (res.data.status === "success") {
          console.log("geographies", JSON.stringify(res.data.result));
          setGeographies(res.data.result);
        }
      })
      .catch(function (error) {
        console.log(JSON.stringify(error));
      });
  }, []);

  const onAddClick = () => {
    setShowAddModal(true);
  };

  const onAddCancel = () => {
    setShowAddModal(false);
  };

  const onEditClick = () => {
    setShowEditModal(true);
  };

  const onEditCancel = () => {
    setShowEditModal(false);
  };

  const columns = [
    {
      title: "Type",
      dataIndex: "geography_type",
      key: "geography_type",
      width: "25%",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "15%",
    },
    {
      title: "Location",
      dataIndex: "vouchers_locations",
      key: "vouchers_locations",
      width: "40%",
      render: (tags) => (
        <>
          {tags.map((tag) => {
            let color = "geekblue";
            return (
              <Tag color={color} key={tag.name}>
                {tag.name.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "  ",
      dataIndex: "operation",
      key: "operation",
      width: "10%",
      render: (text, record) => {
        return (
          <Row style={{ justifyContent: "space-evenly" }}>
            <EditOutlined
              onClick={() => {
                setEditContent(record);
                setLocationOptions(
                  record.vouchers_locations.map((item) => {
                    return { label: item.name, value: item.id };
                  })
                );
                setShowEditModal(true);
                console.log("record edit", record);
              }}
            />
            <DeleteOutlined
              onClick={() => {
                handleDelete(record);
              }}
            />
          </Row>
        );
      },
    },
  ];
  return (
    <Base menuKey="8-1">
      <Col span={3} style={{ marginTop: 20 }}>
        <Row style={{ justifyContent: "space-around" }}>
          <Title level={3}>
            <Text style={{ marginLeft: 20 }} type="secondary">
              Geography
            </Text>
          </Title>
        </Row>
      </Col>

      <Row
        name="row2"
        style={{
          justifyContent: "space-between",
          paddingRight: 25,
          marginBottom: 10,
        }}
      >
        <Col span={10} style={{ paddingLeft: 25 }}>
          <Input
            placeholder={"Search Type / Title"}
            onChange={(event) => {
              setNameSearch(event.target.value);
            }}
          />
        </Col>
        <Button onClick={onAddClick}>+Add Geography</Button>
        <EditGeographyModal
          onOk={onEditClick}
          onCancel={onEditCancel}
          visible={showEditModal}
          content={editContent}
          locationOptions={locationOptions}
        />
        <AddGeographyModal
          onOk={onAddClick}
          onCancel={onAddCancel}
          visible={showAddModal}
        />
      </Row>

      <Row
        style={{
          justifyContent: "center",
          paddingTop: 20,
        }}
      >
        <Col
          span={23}
          style={{
            justifyContent: "flex-start",
          }}
        >
          <Table columns={columns} dataSource={filterData} />
        </Col>
      </Row>
    </Base>
  );
};
