import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Table, Typography } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AxiosConfig } from "../../APIConfig";
import { authHeaders } from "../../auth";
import Base from "../Base";
import { AddLocationModal } from "./Modals/AddLocationModal";
import { EditLocationModal } from "./Modals/EditLocationModal";

export const Location = () => {
  const { Title, Text } = Typography;
  const [editContent, setEditContent] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [,setLoading]=useState(false)

  const [locations, setLocations] = useState([]);

  const onEditOk = () => {
    setShowEditModal(false);
  };
  const onEditCancel = () => {
    setShowEditModal(false);
  };

  const addLocationClicked = () => {
    setShowAddModal(true);
  };

  const onAddOk = () => {
    setShowAddModal(false);
  };
  const onAddCancel = () => {
    setShowAddModal(false);
  };
  const handleDelete = (record) => {
    let obj = {};
    obj.id = record.id;
    console.log("record delete", obj);
    let url = `/vouchers/location/delete`;
    setLoading(true);
    AxiosConfig.delete(url, { headers: authHeaders(), data: obj })
      .then((res) => {
        console.log("delete category response", JSON.stringify(res));
        setLoading(false);
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error, "error");
        setLoading(false);
      });
  };

  const search = (val) => {
    const url = "/vouchers/location/search?input=" + val;
    AxiosConfig.get(url, { headers: authHeaders() })
      .then((res) => {
        // console.log("res search", JSON.stringify(res));
        if (res.data.status === "success") {
          // console.log('res data',JSON.stringify(res.data.result.data))
          setLocations(res.data.result.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    const url = "/vouchers/location";

    AxiosConfig.get(url, { headers: authHeaders() })
      .then((res) => {
        if (res.data.status === "success") {
          console.log("geographies", JSON.stringify(res.data));
          setLocations(res.data.result);
        }
      })
      .catch(function (error) {
        console.log(JSON.stringify(error));
      });
  }, []);

  const columns = [
    {
      title: "Type",
      dataIndex: "location_type",
      key: "location_type",
      width: "30%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "30%",
    },
    {
      title: "Alias",
      dataIndex: "alias",
      key: "alias",
      width: "30%",
    },

    {
      title: "  ",
      dataIndex: "operation",
      key: "operation",
      width: "10%",
      render: (text, record) => {
        return (
          <Row style={{ justifyContent: "space-evenly" }}>
            <EditOutlined
              onClick={() => {
                setEditContent(record);
                setShowEditModal(true);
                console.log("record edit", record);
              }}
            />
            <DeleteOutlined
              onClick={() => {
                handleDelete(record)
              }}
            />
          </Row>
        );
      },
    },
  ];
  return (
    <Base menuKey="8-3">
      <Col span={3} style={{ marginTop: 20 }}>
        <Row style={{ justifyContent: "space-around" }}>
          <Title level={3}>
            <Text type="secondary">Location</Text>
          </Title>
        </Row>
      </Col>

      <Row
        name="row2"
        style={{
          justifyContent: "space-between",
          paddingRight: 25,
          marginBottom: 10,
        }}
      >
        <Col span={10} style={{ paddingLeft: 25 }}>
          <Input
            placeholder={"Search Location"}
            onChange={(event) => {
              search(event.target.value);
            }}
          />
        </Col>
        <Button onClick={addLocationClicked}>+ Add Location</Button>
        <AddLocationModal
          onOk={onAddOk}
          onCancel={onAddCancel}
          visible={showAddModal}
        />
        <EditLocationModal
          onOk={onEditOk}
          onCancel={onEditCancel}
          content={editContent}
          visible={showEditModal}
        />
      </Row>

      <Row
        style={{
          justifyContent: "center",
          paddingTop: 20,
        }}
      >
        <Col
          span={23}
          style={{
            justifyContent: "flex-start",
          }}
        >
          <Table columns={columns} dataSource={locations} />
        </Col>
      </Row>
    </Base>
  );
};
