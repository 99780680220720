import { Button, Row } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { AxiosConfig } from "../../../APIConfig";
import {
  authHeaders,
  getrnum,
  setUserSession,
  getstore,
  getSaveButtonClicked,
} from "../../../auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { css } from "glamor";
// import { useHistory } from "react-router-dom";
toast.configure();

export const AwardPoints = (props) => {
  const [load, setload] = useState(false);
  const [showReturned, setShowReturned] = useState(true);
  const [showAwardPoints, setShowAwardPoints] = useState(true);
  const [showReject, setShowReject] = useState(true);
  // const history = useHistory();

  const [receiptStatus, setReceiptStatus] = useState();
  useEffect(() => {
    if (receiptStatus === "Inprogress") {
      console.log("status: inprogress");
      setShowReturned(false);
    } else if (receiptStatus === "Rejected") {
      console.log("status: Rejected");
      setShowReturned(false);
      setShowReject(false);
    } else if (receiptStatus === "Returned") {
      console.log("status: Rejected");
      setShowReturned(false);
      setShowReject(false);
    } else if (receiptStatus === "Processed") {
      console.log("status: Processed");
      setShowAwardPoints(false);
      setShowReject(false);
    }
  }, [receiptStatus]);

  console.log("receipt status in award points screen", receiptStatus);
  useEffect(() => {
    const rnum = getrnum();
    let url2 = `/receipts?receipt_id=${rnum}&limit=-1&offset=-1`;
    AxiosConfig.get(url2, { headers: authHeaders() })
      .then((res) => {
        if (res.data.status === "success") {
          setReceiptStatus(
            res.data.result.data[0].processing_master_status.status
          );
        }
      })

      .catch(function (error) {
        console.log(JSON.stringify(error));
      });
  }, []);

  // const resolve = () => {
  //   setload(true);
  //   const token = getToken();
  //   const rnum = getrnum();
  //   let headers = authHeaders();

  //   const dataresolve = {
  //     receipt_id: parseInt(rnum),
  //     is_resolve: true,
  //   };

  //   let url1 = "/receipts/resolve";
  //   AxiosConfig.post(url1, dataresolve, { headers })
  //     .then((res) => {
  //       setload(false);

  //       setTimeout(reload, 1300);
  //       setrnum(rnum);
  //     })
  //     .catch(function (error) {
  //       if (error.response.status === 420) {
  //         let url2 = "/refreshtoken";
  //         let body = { token: token };
  //         AxiosConfig.post(url2, body, { headers })
  //           .then((res) => {
  //             setUserSession(res.data.result.token);
  //             award();
  //           })
  //           .catch(function (error) {
  //             console.log(error);
  //           });
  //       }
  //       console.log(error);
  //     });
  // };

  const award = () => {
    if (getSaveButtonClicked()) {
      setload(true);
      const rnum = getrnum();
      let headers = authHeaders();
      var data = {
        receipt_id: parseInt(rnum),
        is_approve: true,
      };
      let url1 = "/receipts/approve";
      const url = `/receipts/item_list?receipt_id=${rnum}`;
      AxiosConfig.get(url, { headers })
        .then((res) => {
          if (res.data.result.data.length !== 0) {
            AxiosConfig.post(url1, data, { headers: authHeaders() })
              .then((res) => {
                //Since the receipt has been awarded,
                // setting the remarks as processed successfully and calling receipt edit api
                const url1 = "/receipts/edit";
                const receiptObject = getstore();
                const receiptDetails = {
                  ...receiptObject,
                  receipt_id: typeof rnum === "string" ? parseInt(rnum) : rnum,
                  receipt_number: receiptObject.receipt_number
                    ? `${receiptObject.receipt_number}`
                    : "",
                  receipt_txn_date:
                    receiptObject.receipt_txn_date === "dd/mm/yyyy"
                      ? "null"
                      : receiptObject.receipt_txn_date,
                  gst_number: receiptObject.gst_number
                    ? receiptObject.gst_number
                    : "",
                  store_name: receiptObject.store_name
                    ? receiptObject.store_name
                    : "",
                  remarks: "Processed Successfully",
                };
                AxiosConfig.post(url1, receiptDetails, {
                  headers: authHeaders(),
                }).catch((error) => {
                  toast(`${error.response.data.message}`);
                });

                setload(false);
                toast(`${res.data.result.message}`);
                localStorage.setItem("menuKey", 4);
                setTimeout(() => {
                  window.location.reload();
                }, 4000);
              })
              .catch(function (error) {
                setload(false);
                toast(`${error.response.data.message}`);
                console.log("error", JSON.stringify(error));
              });
          } else {
            toast(`Please Add and save Line Items`);
            setload(false);
          }
        })
        .catch(function (error) {
          if (error.response.status === 420) {
            let url2 = "/refreshtoken";

            AxiosConfig.post(url2, { headers: authHeaders() })
              .then((res) => {
                setUserSession(res.data.result.token);
                award();
              })
              .catch(function (error) {
                console.log(error);
              });
          } else {
            console.log(`ERROR ${JSON.stringify(error.response.data.message)}`);
          }
        });
    } else {
      toast("Reciept Details and Line Items Have not Been Saved");
    }
  };

  const returnPoints = () => {
    setload(true);
    const rnum = getrnum();
    let headers = authHeaders();

    let data = {
      receipt_id: parseInt(rnum),
    };

    let url1 = "/receipts/return";
    const url = `/receipts/item_list?receipt_id=${rnum}`;
    AxiosConfig.get(url, { headers })
      .then((res) => {
        if (res.data.result.data.length !== 0) {
          AxiosConfig.post(url1, data, { headers: authHeaders() })
            .then((res) => {
              setload(false);
              toast(`${res.data.result.message}`);
              localStorage.setItem("menuKey", 3);
              setTimeout(() => {
                window.location.reload();
              }, 4000);
            })
            .catch(function (error) {
              setload(false);
              toast(`${error.response.data.message}`);
              console.log("error", JSON.stringify(error));
            });
        } else {
          console.log("There are no items to award points");
          setload(false);
        }
      })
      .catch(function (error) {
        if (error.response.status === 420) {
          let url2 = "/refreshtoken";

          AxiosConfig.post(url2, { headers: authHeaders() })
            .then((res) => {
              setUserSession(res.data.result.token);
              award();
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          toast(`${error.response.data.message}`);
          console.log(`ERROR ${JSON.stringify(error.response.data.message)}`);
        }
      });
  };

  const reject = () => {
    setload(true);
    const rnum = getrnum();
    const store = getstore();
    let data = {
      receipt_id: parseInt(rnum),
      is_approve: false,
      notes: store.remarks && store.remarks !== undefined ? store.remarks : " ",
    };
    let url1 = "/receipts/approve";
    //make sure receipt details remarks is not null or undefined or ''
    AxiosConfig.post(url1, data, { headers: authHeaders() })
      .then((res) => {
        setload(false);
        console.log("reject response", JSON.stringify(res.data));
        toast(`${res.data.result.message}`, {
          className: css({
            background: "#00FF00 !important",
            color: "red",
            fontWeight: "bold",
          }),
        });
        localStorage.setItem("menuKey", 4);
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      })
      .catch(function (error) {
        if (error.response.status === 420) {
          let url2 = "/refreshtoken";

          AxiosConfig.post(url2, { headers: authHeaders() })
            .then((res) => {
              setUserSession(res.data.result.token);
              award();
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          toast(`${error.response.data.message}`);
          console.log(`ERROR ${JSON.stringify(error.response.data.message)}`);
        }
      });
  };

  return (
    <Row>
      {showAwardPoints && (
        <Button
          onClick={award}
          disabled={load}
          type="primary"
          style={{
            width: 120,
          }}
        >
          Award Points
        </Button>
      )}

      {showReturned && (
        <Button
          type="primary"
          disabled={load}
          onClick={returnPoints}
          danger
          style={{
            marginLeft: 10,
            width: 120,
          }}
        >
          Return Points{" "}
        </Button>
      )}

      {showReject && (
        <Button
          type="primary"
          disabled={load}
          onClick={reject}
          danger
          style={{
            marginLeft: 10,
            width: 120,
          }}
        >
          Reject
        </Button>
      )}
    </Row>
  );
};
