import { Col, Input, Row, Table } from "antd";
import React from "react";
import Base from "../Base";
import { Typography } from "antd";
import { authHeaders } from "../../auth";
import { AxiosConfig } from "../../APIConfig";
import { useEffect } from "react";
import { useState } from "react";
export const Stores = (data) => {
  const { Title, Text } = Typography;
  const columns = [
    {
      title: "Til ID",
      dataIndex: "tillid",
      key: "tillid",
      width: "30%",
    },
    {
      title: "Store Name",
      dataIndex: "store_name",
      key: "store_name",
      width: "30%",
    },

    {
      title: "Address",
      dataIndex: "address_line1",
    },
  ];
  const [, setLoading] = useState(false);
  const [stores, setStores] = useState([]);

  const [filterData, setFilterData] = useState([]);
  const [nameSearch, setNameSearch] = useState("");

  useEffect(() => {
    if (stores) {
        const results = stores.filter(
            (item) =>
                item.store_name
                    .toLowerCase()
                    .includes(nameSearch.toLowerCase()) ||
                item.tillid
                    .toLowerCase()
                    .includes(nameSearch.toLowerCase()),
        );
        setFilterData(results);
    }
}, [nameSearch,stores]);
  useEffect(() => {
    let url1 = `/stores?limit=-1&offset=-1`;
    setLoading(true);
    AxiosConfig.get(url1, { headers: authHeaders() })
      .then((res) => {
        if (res.data.status === "success") {
          console.log("stores", res.data.result.data);
          setStores(res.data.result.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }, []);
  return (
    <Base menuKey={"1-6"}>
      <Row style={{ marginBottom: 10 }}>
        <Col span={10} style={{ marginTop: 20 }}>
          <Title level={3}>
            <Text type="secondary">Stores</Text>
          </Title>
          <Input
            placeholder={"search value"}
            onChange={(event) => {
              setNameSearch(event.target.value);
            }}
          />
        </Col>
      </Row>

      <Table columns={columns} dataSource={filterData} />
    </Base>
  );
};
