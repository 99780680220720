import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Modal, Row, Select } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";
import { toast } from "react-toastify";

import { AxiosConfig } from "../../../APIConfig";
import { authHeaders } from "../../../auth";
toast.configure();
export const EditUser = (props) => {
  const [form] = Form.useForm();

  form.setFieldsValue({
    user_name: props.content.user_name,
    password: "",
    retype_password: "",
    roll_id: props.content.roll_id,
  });

  const roleOptions = [
    {
      label: "Super Admin",
      value: 1,
    },
    {
      label: "Admin",
      value: 2,
    },
  ];

  const onEditCancel = () => {
    form.resetFields();
    props.onCancel();
  };
  const onEditOk = () => {
    props.onCancel();
  };
  const handleSubmit = (val) => {
    const { retype_password, ...obj } = val;
    obj.user_id = props.content.admin_id;

    if (
      obj.password === val.retype_password &&
      obj.password === "" &&
      Number.isInteger(obj.roll_id)
    ) {
      const { password, ...reqObject } = obj;
      if (obj.roll_id < 5) {
        const url = "/users/edit";
        AxiosConfig.post(url, reqObject, { headers: authHeaders() })
          .then((res, req) => {
            if (res.data.status === "success") {
              console.log("add User response", res);
              window.location.reload();
            } else {
              console.log("password absent res", res);
            }
          })
          .catch(function (error) {
            toast(`${error.response.data.message}`);
          });
      }
    } else if (
      obj.user_name !== "" &&
      obj.password !== "" &&
      obj.password === val.retype_password &&
      Number.isInteger(obj.roll_id)
    ) {
      if (obj.roll_id < 3) {
        const url = "/users/edit";
        AxiosConfig.post(url, obj, { headers: authHeaders() })
          .then((res, req) => {
            if (res.data.status === "success") {
              console.log("add User response", res);
              window.location.reload();
            } else {
              console.log("password present res", res);
            }
          })
          .catch(function (error) {
            toast(`${error.response.data.message}`);
          });
      }
    }
  };

  return (
    <Modal
      onOk={onEditOk}
      onCancel={onEditCancel}
      open={props.visible}
      closable={false}
      maskClosable={false}
      footer={null}
    >
      <Row style={{ justifyContent: "space-between" }}>
        <Text style={{ fontFamily: "sans-serif", fontSize: 20 }}>
          Edit User
        </Text>
        <CloseCircleOutlined style={{ fontSize: 18 }} onClick={onEditCancel} />
      </Row>
      <Divider />
      <Form form={form} layout="horizontal" onFinish={handleSubmit}>
        <Form.Item
          name="user_name"
          label="User Name"
          style={{ left: 100 }}
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
          ]}
        >
          <Input autoComplete="off" style={{ width: 350, left: 42 }} />
        </Form.Item>

        <Form.Item name="password" label="Change Password">
          <Input.Password placeholder="input password" style={{ width: 350 }} />
        </Form.Item>

        <Form.Item name="retype_password" label="Confirm Password">
          <Input.Password placeholder="input password" style={{ width: 350 }} />
        </Form.Item>

        <Form.Item name="roll_id" label="Role">
          <Select options={roleOptions} style={{ width: 350, left: 85 }} />
        </Form.Item>

        <Form.Item>
          <Row style={{ justifyContent: "space-evenly" }}>
            <Button type="primary" htmlType="submit">
              SAVE
            </Button>

            <Button type="primary" onClick={onEditCancel}>
              Cancel
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};
