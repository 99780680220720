import { Table, Row, Col, Input, Button } from "antd";
import Text from "antd/lib/typography/Text";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { setrnum } from "../../auth";

const AwardReceiptsTable = (props) => {
  const { data, tableParams, setTableParams, loading } = props;
  const history = useHistory();

  const [actualData, setActualData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [nameSearch, setNameSearch] = useState("");

  const viewDetail = (record) => {
    const { receipt_id } = record;
    setrnum(receipt_id);
    history.push({ pathname: `/details/${receipt_id}`, state: record });
  };

  useEffect(() => {
    setActualData(data);
    setFilterData(data);
  }, [data]);

  useEffect(() => {
    if (actualData) {
      const results = actualData.filter(
        (item) =>
          item.receipt_number
            .toLowerCase()
            .includes(nameSearch.toLowerCase()) ||
          item.customer_external_id
            .toLowerCase()
            .includes(nameSearch.toLowerCase()) ||
          item.created_at.toLowerCase().includes(nameSearch.toLowerCase())
      );
      setFilterData(results);
    }
  }, [nameSearch, actualData]);

  const columns = [
    {
      title: "Receipt ID",
      dataIndex: "receipt_number",
      key: "receipt_number",
    },
    {
      title: "Submission Date",
      dataIndex: "created_at",
      key: "created_at",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        a.created_at
          .split("-")
          .reverse()
          .join("")
          .localeCompare(b.created_at.split("-").reverse().join("")),
    },
    {
      title: "User ID",
      dataIndex: "customer_external_id",
      key: "customer_external_id",
      sorter: (a, b) =>
        a.customer_external_id.localeCompare(b.customer_external_id),
    },
    {
      title: "Receipt Date",
      dataIndex: "receipt_txn_date",
      key: "receipt_txn_date",
    },
    {
      title: "Receipt Status",
      dataIndex: "processing_master_status",
      key: "processing_master_status",

      sorter: (a, b) =>
        a.processing_master_status.status.localeCompare(
          b.processing_master_status.status
        ),
      render: (item) => {
        return <Text>{item.status}</Text>;
      },
    },
    {
      title: "OCR Confidence",
      dataIndex: "ocr_confident_level",
      key: "ocr_confident_level",
      sorter: (a, b) => a.ocr_confident_level - b.ocr_confident_level,
      render: (item) => {
        return <Text>{item.toFixed(2) + "%"}</Text>;
      },
    },
    {
      title: "Awarded Points",
      dataIndex: "points_awarded",
      key: "points_awarded",
    },
    {
      title: "Remark",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "",
      render: (record) => (
        <>
          <Button className="ant-btn-bira" onClick={() => viewDetail(record)}>
            View
          </Button>
        </>
      ),
    },
  ];

  const handleTableChange = (pagination) => {
    console.log("pagination =>", pagination);
    setTableParams({
      pagination,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setFilterData([]);
    }
  };

  return (
    <div>
      <Row className="mb-1">
        <Col span={14}>
          <Input
            allowClear
            onChange={(e) => setNameSearch(e.target.value)}
            placeholder="Search Receipt Number/ User Id / Submission Date(DD-MM-YYYY)"
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={filterData}
        bordered
        pagination={tableParams && tableParams?.pagination}
        loading={loading}
        onChange={handleTableChange}
        // pagination={{
        //   defaultPageSize: 10,
        //   showSizeChanger: true,
        //   pageSizeOptions: ["10", "20", "30"],
        // }}
      />
    </div>
  );
};

export default AwardReceiptsTable;
