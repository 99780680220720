import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Spin, Table, Typography } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AxiosConfig } from "../../APIConfig";
import { authHeaders, headers } from "../../auth";
import Base from "../Base";
import { AddCategoryModal } from "./Modals/AddCategoryModal";
import { EditCategoryModal } from "./Modals/EditCategoryModal";

export const Category = () => {
  const { Title, Text } = Typography;
  const [editContent, setEditContent] = useState({});

  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const search = (val) => {
    if (val !== "") {
      const url = "/vouchers/category/search?input=" + val;
      AxiosConfig.get(url, { headers: authHeaders() })
        .then((res) => {
          // console.log("res search", JSON.stringify(res));
          if (res.data.status === "success") {
            // console.log('res data',JSON.stringify(res.data.result.data))
            setCategories(res.data.result.data);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const showAddCategoryModal = () => {
    setShowAddModal(true);
  };
  const onEditOk = () => {
    setShowEditModal(false);
  };
  const onEditCancel = () => {
    setShowEditModal(false);
  };

  const onAddOk = () => {
    setShowAddModal(false);
  };
  const onAddCancel = () => {
    setShowAddModal(false);
  };

  const handleDelete = (record) => {
    let obj = {};
    obj.id = record.id;
    console.log("record delete", obj);
    let url = `/vouchers/category/delete`;
    setLoading(true);
    AxiosConfig.delete(url, { headers: authHeaders(), data: obj })
      .then((res) => {
        console.log("delete category response", JSON.stringify(res));
        setLoading(false);
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error, "error");
        setLoading(false);
      });
  };

  useEffect(() => {
    const url = "/vouchers/category";
    setLoading(true)
    AxiosConfig.get(url, { headers })
      .then((res) => {
        if (res.data.status === "success") {
          console.log("categories", JSON.stringify(res.data));
          setCategories(res.data.result);
          setLoading(false)
        }
      })
      .catch(function (error) {
        console.log(JSON.stringify(error));
        setLoading(false)
      });
  }, []);

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: "90%",
    },
    {
      title: "  ",
      dataIndex: "operation",
      key: "operation",
      width: "10%",
      render: (text, record) => {
        return (
          <Row style={{ justifyContent: "space-evenly" }}>
            <EditOutlined
              onClick={() => {
                setEditContent(record);
                setShowEditModal(true);
                console.log("record edit", record);
              }}
            />
            <DeleteOutlined
              onClick={() => {
                handleDelete(record);
              }}
            />
          </Row>
        );
      },
    },
  ];
  return (
    <Base menuKey="8-2">
      {loading && (
        <Row
          style={{
            height: 500,
            width: 1200,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin spinning={loading} delay={500} />
        </Row>
      )}
      {!loading &&
        <Col>
          <Col span={3} style={{ marginTop: 20 }}>
            <Row style={{ justifyContent: "space-around" }}>
              <Title level={3}>
                <Text type="secondary">Category</Text>
              </Title>
            </Row>
          </Col>
          <Row
            name="row2"
            style={{
              justifyContent: "space-between",
              paddingRight: 25,
              marginBottom: 10,
            }}
          >
            <Col span={10} style={{ paddingLeft: 25 }}>
              <Input
                placeholder={"Search Categories.."}
                onChange={(event) => {
                  search(event.target.value);
                }}
              />
            </Col>
            <Button onClick={showAddCategoryModal}>+Add Category</Button>
          </Row>

          <Row
            style={{
              justifyContent: "center",
              paddingTop: 20,
            }}
          >
            <Col
              span={23}
              style={{
                justifyContent: "flex-start",
              }}
            >
              <EditCategoryModal
                onOk={onEditOk}
                onCancel={onEditCancel}
                content={editContent}
                visible={showEditModal}
              />
              <AddCategoryModal
                onOk={onAddOk}
                onCancel={onAddCancel}
                visible={showAddModal}
              />
              <Table columns={columns} dataSource={categories} />
            </Col>
          </Row>
        </Col>
      }
    </Base>
  );
};
