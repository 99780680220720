/* eslint-disable react-hooks/exhaustive-deps */
import Base from "../Base";
import { Row, Typography } from "antd";
import { authHeaders, setpage } from "../../auth";
import { AxiosConfig } from "../../APIConfig";
import { useEffect, useState } from "react";
import ReceiptTable from "./ReceiptTable";
import moment from "moment";

const Returned = () => {
  const { Title, Text } = Typography;

  const [returnedData, setReturnedData] = useState([]);

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [loading, setLoading] = useState(false);

  const getFlaggedReceipt = () => {
    setLoading(true);
    // let offsetVal =
    //   (tableParams.pagination.current - 1) * tableParams.pagination.pageSize;

    // let limitVal = tableParams.pagination.pageSize;

    AxiosConfig.get(`/receipts/returned`, { headers: authHeaders() })
      .then((res) => {
        console.log("getReturnedReceipt res =>", res);
        const temp = res.data.data.map((v) => {
          return {
            ...v,
            receipt_number: v.receipt_number ?? "-",
            created_at: moment(v.created_at).format("DD-MM-YYYY"),
            customer_external_id: v.customer_external_id ?? "-",
            receipt_txn_date: !v.receipt_txn_date
              ? "No Date"
              : moment(v.receipt_txn_date).format("DD-MM-YYYY"),
            ocr_confident_level: v.ocr_confident_level ?? 0,
            remarks: v.remarks ?? "-",
          };
        });
        setLoading(false);
        setReturnedData(temp);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.data.count,
          },
        });
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    setpage("/returned");
    getFlaggedReceipt();
  }, [JSON.stringify(tableParams)]);

  return (
    <Base menuKey="3">
      <Row style={{ marginTop: 20 }}>
        <Title level={3}>
          <Text type="secondary">Returned Receipts</Text>
        </Title>
      </Row>

      <ReceiptTable
        data={returnedData}
        tableParams={tableParams}
        setTableParams={setTableParams}
        loading={loading}
      />
    </Base>
  );
};

export default Returned;
