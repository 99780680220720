import { Menu, Row, Col} from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AxiosConfig } from "../APIConfig";
import logo from "../assets/logo.png";
import {
  authHeaders,
  getToken,
  setUserSession,
  headers,
  getAdmin,
} from "../auth";
import Logout from "./Logout";
import ReportsMenu from "./Reports/ReportsMenu";
require("../styles/Base.scss");

const Base = ({ menuKey = "1", children }) => {
  const { Item, SubMenu } = Menu;
  const [receiptsCount, setReeiptsCount] = useState({});
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  // const [reportType, setReportType] = useState(null);

  const { isLoggedIn } = useSelector((state) => state.auth);

  localStorage.setItem("menuKey", menuKey);


  useEffect(() => {
    console.log("Is User Logged In:", isLoggedIn);
    setIsSuperAdmin(getAdmin());
    const getReceiptsCount = () => {
      const url = "/receipts/count";
      AxiosConfig.get(url, { headers: authHeaders() })
        .then((res) => {
          //console.log("res =>", res, "\n", res?.response);
          if (res.data.status === "success") {
            // console.log('Receipt Counts',res.data.result)
            setReeiptsCount(res.data.result);
          }
        })
        .catch(function (error) {
          console.log("error =>", error, "\n", error?.response);
          if (error.response?.status === 420) {
            let url2 = "/refreshtoken";
            let body = { token: getToken() };
            AxiosConfig.post(url2, body, { headers })
              .then((res) => {
                setUserSession(res.data.result.token);
                getReceiptsCount();
              })
              .catch(function (error) {
                console.log(error);
              });
          }
          console.log(error);
        });
    };
    getReceiptsCount();
  }, [isLoggedIn]);

  return (
    <div className="base-div">
      <Row className="header">
        <Col span={18}>
          <img src={logo} alt="logo" className="logos" />
        </Col>
        <Col span={6} className="logout-css">
          <Logout />
        </Col>
      </Row>
      <Row style={{ minHeight: "92vh" }}>
        <Col span={4}>
          <Menu
            mode="inline"
            defaultSelectedKeys={[menuKey]}
            style={{ height: "100%" }}
          >
            <Item key="1">
              Flagged Receipts [{receiptsCount.flagged}]{" "}
              <Link to={"/flagged"}></Link>
            </Item>
            <Item key="2">
              Pending Receipts [{receiptsCount.pending}]{" "}
              <Link to={"/pending"}></Link>
            </Item>
            <Item key="3">
              Returned Receipts [{receiptsCount.returned}]{" "}
              <Link to={"/returned"}></Link>
            </Item>
            <Item key="4">
              Awarded Receipts [{receiptsCount.awarded}]
              <Link to={"/awarded"}></Link>
            </Item>
            <Item key="5">
              Search <Link to={"/search"}></Link>
            </Item>
            <Item key="6">
              Stores <Link to={"/stores"}></Link>
            </Item>
            {isSuperAdmin === "true" && (
              <SubMenu key="sub1" title="Administration">
                {/* <Item key="7-1">
               OCR Templates <Link to={"/ocr"}></Link>
             </Item> */}
                <Item key="7-1">
                  Access Control <Link to={"/access-control"}></Link>
                </Item>
                <Item key="7-2">
                  Rewards <Link to={"/rewards"}></Link>
                </Item>
              </SubMenu>
            )}
            {isSuperAdmin === "true" && (
              <SubMenu key="sub2" title="Master Data">
                <Item key="8-1">
                  Geography <Link to={"/geography"}></Link>
                </Item>
                <Item key="8-2">
                  Category <Link to={"/category"}></Link>
                </Item>
                <Item key="8-3">
                  Location <Link to={"/location"}></Link>
                </Item>
              </SubMenu>
            )}
            {/* {isSuperAdmin === "true" && (
              <SubMenu key="sub3" title="Reports">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    margin: "0 15px",
                    alignItems: "center",
                    textAlign: "center",
                    margin: "8px",
                  }}
                >
                  <p style={{ margin: "auto 14%" }}>Bill Scan</p>
                  <img
                    src={download}
                    style={{ height: "30px", width: "30px" }}
                    onClick={() => {
                      setReportType("bill_scan");
                      setShowModal(true);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    margin: "0 15px",
                    alignItems: "center",
                    textAlign: "center",
                    margin: "20px 8px",
                  }}
                >
                  <p style={{ margin: "auto 14%" }}>Beer Rating</p>
                  <img
                    src={download}
                    style={{ height: "30px", width: "30px" }}
                    onClick={() => {
                      // setReportType("beer_rating");
                      // setShowModal(true);
                      handleDownloadBeerRatingReport();
                    }}
                  />
                </div>
                {showModal ? (
                  <ReportsModal
                    reportType={reportType}
                    showModal={showModal}
                    handleCancel={() => handleCancel(false)}
                  />
                ) : null}
              </SubMenu>
            )} */}
            <SubMenu key="sub3" title="Reports">
            {isSuperAdmin === "true" ? <ReportsMenu/> : null}
            </SubMenu>
          </Menu>
        </Col>
        <Col span={20} className="children-div">
          <div>{children}</div>
        </Col>
      </Row>
    </div>
  );
};

export default Base;
