import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Modal, Row, Select, Tag } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { AxiosConfig } from "../../../APIConfig";
import { authHeaders } from "../../../auth";

function tagRender(props) {
  const { label, closable, onClose } = props;

  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  );
}

export const EditGeographyModal = (props) => {
  const [, setLocationOptions] = useState([]);
  const [locations, setLocations] = useState([]);

  console.log("edit props", props.content);

  const [form] = Form.useForm();
  form.setFieldsValue({
    title: props.content.title,
    geography_type: props.content.geography_type,
    is_active: props.content.is_active,
    locations: props.locationOptions !== undefined ? props.locationOptions : [],
  });

  useEffect(() => {
    const url = "/vouchers/location";

    AxiosConfig.get(url, { headers: authHeaders() })
      .then((res) => {
        if (res.data.status === "success") {
          const location = res.data.result.map((item) => {
            return { label: item.name, value: item.id };
          });
          setLocations(location);
        }
      })
      .catch(function (error) {
        console.log(JSON.stringify(error));
      });
  }, []);

  useEffect(() => {
    setLocationOptions(props.locationOptions);
  }, [props.locationOptions]);

  const geographyOptions = [
    {
      label: "City",
      value: "city",
    },
    {
      label: "State",
      value: "state",
    },
    {
      label: "Country",
      value: "country",
    },
  ];

  const statusOptions = [
    {
      label: "Active",
      value: 1,
    },
    {
      label: "Inactive",
      value: 0,
    },
  ];

  const onEditCancel = () => {
    form.resetFields();
    props.onCancel();
  };
  const onEditOk = () => {
    props.onCancel();
  };
  const handleSubmit = (val) => {
    const obj = JSON.parse(JSON.stringify(val));
    obj.locations.map((item) => {
      return item.value;
    });
    obj.id = props.content.id;

    if (
      obj.title !== undefined &&
      obj.locations !== undefined &&
      obj.geography_type !== undefined &&
      obj.geography_type.length > 0 &&
      obj.is_active !== undefined
    ) {
      const url = "/vouchers/geography/update";
      AxiosConfig.put(url, obj, { headers: authHeaders() })
        .then((res) => {
          if (res.data.status === "success") {
            console.log("add geography response", res);
            window.location.reload();
          }
        })
        .catch(function (error) {
          console.log(JSON.stringify(error));
        });
    }

    console.log("Submitted locations array", obj);
  };

  return (
    <Modal
      onOk={onEditOk}
      onCancel={onEditCancel}
      visible={props.visible}
      closable={false}
      maskClosable={false}
      footer={null}
    >
      <Row style={{ justifyContent: "space-between" }}>
        <Text style={{ fontFamily: "sans-serif", fontSize: 20 }}>
          Edit Geography
        </Text>
        <CloseCircleOutlined style={{ fontSize: 18 }} onClick={onEditCancel} />
      </Row>
      <Divider />
      <Form form={form} layout="horizontal" onFinish={handleSubmit}>
        <Form.Item name="title" label="Title">
          <Input autoComplete="off" style={{ width: 350, left: 78 }} />
        </Form.Item>

        <Form.Item name="geography_type" label="Geography Type">
          <Select options={geographyOptions} style={{ width: 350 }} />
        </Form.Item>

        <Form.Item label="Locations" name="locations">
          <Select
            options={locations}
            tagRender={tagRender}
            mode="multiple"
            style={{ width: 350, left: 43 }}
          />
        </Form.Item>

        <Form.Item name="is_active" label="Status">
          <Select options={statusOptions} style={{ width: 350, left: 62 }} />
        </Form.Item>
        <Form.Item>
          <Row style={{ justifyContent: "space-evenly" }}>
            <Button type="primary" htmlType="submit">
              SAVE
            </Button>

            <Button type="primary" onClick={onEditCancel}>
              Cancel
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};
