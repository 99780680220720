import { BrowserRouter, Switch, Route } from "react-router-dom";
import Login from "./components/Login";
import PrivateRoute from "./auth/helper/PrivateRoutes";
import Flagged from "./components/Receipts/Flagged";
import Pending from "./components/Receipts/Pending";
import Returned from "./components/Receipts/Returned";
import Awarded from "./components/Receipts/Awarded";
import ReceiptDetail from "./components/Receipts/ReceiptDetail";
import { AccessControl } from "./components/Administration/AccessControl/AccessControl";
import { Geography } from "./components/MasterData/Geography";
import { Location } from "./components/MasterData/Location";
import { Category } from "./components/MasterData/Category";
import { Rewards } from "./components/Rewards/Rewards";
import { Stores } from "./components/Receipts/stores";
import { ViewHistory } from "./components/Receipts/ViewHistory";
import { Search } from "./components/Receipts/Search";
import DeleteAccount from "./components/deleteAccount/deleteAccount"

const Router = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Login} />
                <Route path="/delete_account" exact component={DeleteAccount}/>
                <PrivateRoute path="/flagged" exact component={Flagged} />
                <PrivateRoute path="/pending" exact component={Pending} />
                <PrivateRoute path="/returned" exact component={Returned} />
                <PrivateRoute path="/awarded" exact component={Awarded} />
                <PrivateRoute path="/access-control" exact component={AccessControl} />
                <PrivateRoute path="/details/:id" exact component={ReceiptDetail} />
                <PrivateRoute path="/viewhistory" exact component={ViewHistory} />
                <PrivateRoute path="/search" exact component={Search} />
                <PrivateRoute path="/stores" exact component={Stores} />
                <PrivateRoute path="/geography" exact component={Geography} />
                <PrivateRoute path="/location" exact component={Location} />
                <PrivateRoute path="/category" exact component={Category} />
                <PrivateRoute path="/rewards" exact component={Rewards} />
            </Switch>
        </BrowserRouter>
    );
};

export default Router;
