import { Button, Col, Divider, Input, InputNumber, Row } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useEffect, useState } from "react";
import { setDenominationStore } from "../../auth";

export const Denominations = (props) => {
  const [denominations, setDenominations] = useState([]);

  useEffect(() => {
    setDenominations(props.denominationData);
  }, [props.denominationData]);

  const handleDenominationChange = (val, i) => {
    const values = [...denominations];
    // console.log("values =>", values, "\n", i, "\n", values[i]);
    values[i].denomination = val;
    values[i].points = values[i].denomination * values[i].multiplier;
    setDenominations(values);
    setDenominationStore(values);
    // console.log("existing dem", denominations);
  };

  const handleMultiplierChange = (val, i) => {
    const values = [...denominations];
    // console.log("values =>", values, "\n", i, "\n", values[i].denomination);
    // if (values[i].denomination) {
    values[i].multiplier = val;
    values[i].points = values[i].denomination * values[i].multiplier;
    setDenominations([...values]);
    setDenominationStore([...denominations]);
    // console.log("existing dem", denominations);
    // }
  };

  const removeExisting = (index) => {
    denominations.splice(index, 1);
    setDenominations([...denominations]);
    setDenominationStore([...denominations]);
    console.log("after deletion", denominations);
  };

  const addDenomination = () => {
    denominations.push({
      denomination: 0,
      multiplier: 0,
      currency: "",
      points: 0,
    });
    setDenominations([...denominations]);
    setDenominationStore([...denominations]);
  };

  const handleCurrencyChange = (val, i) => {
    const values = [...denominations];
    values[i].currency = val;
    setDenominations([...values]);
    setDenominationStore([...values]);
    // console.log("existing dem", denominations);
  };

  return (
    <div>
      {props.min && (
        <Text>
          Denomination Range is between {props.min} and {props.max}{" "}
        </Text>
      )}
      <Divider />
      <Button
        type="primary"
        style={{ marginBottom: 60 }}
        onClick={addDenomination}
      >
        Add Denomination
      </Button>

      {denominations.map((item, index) => {
        return (
          <Row style={{ justifyContent: "space-between" }}>
            <Col> {index + 1 + ")"}</Col>
            <Col span={10}>
              <Input
                addonBefore={"Currency"}
                defaultValue={item.currency}
                style={{ marginBottom: 20 }}
                onChange={(e) => {
                  handleCurrencyChange(e.target.value, index);
                }}
              />

              <InputNumber
                addonBefore={"Denomination"}
                defaultValue={item.denomination}
                style={{ marginBottom: 20 }}
                onChange={(val) => {
                  handleDenominationChange(val, index);
                }}
              />
              <Button
                type="primary"
                style={{ backgroundColor: "red" }}
                onClick={() => {
                  removeExisting(index);
                }}
              >
                Remove
              </Button>
            </Col>
            <Col span={12}>
              <InputNumber
                style={{ width: 400, marginBottom: 20 }}
                addonBefore={"Multiplier"}
                defaultValue={item.multiplier ? item.multiplier : 0}
                onChange={(val) => {
                  handleMultiplierChange(val, index);
                }}
              />
              <Col>
                {denominations[index] && denominations[index].points && (
                  <Text>
                    {" "}
                    Points :{" "}
                    {Math.round(denominations[index].points.toFixed(3))}{" "}
                  </Text>
                )}
              </Col>
            </Col>
            <Divider />
          </Row>
        );
      })}

      {/* <Divider /> */}
    </div>
  );
};
