import { Col, Image } from 'antd'
import React from 'react'
import { useState } from 'react'
//import { cloudFrontUrl } from '../../../auth'

export const RecieptImage = (props) => {
    const {receipt_images}=props.data
    const recieptImage=Array.isArray(receipt_images)&& receipt_images.length>0 ?receipt_images[0]:[]
    const [visible, setVisible] = useState(false);
  
    /**check for the older images if the complete img link is present */
    const backupImg=recieptImage?.image_link?? 'https://cdn.codespeedy.com/wp-content/uploads/2019/03/Chrome-Broken-Image-Icon.png';
    const imageLink= recieptImage?.image_link? `${recieptImage?.image_link}`: backupImg
  
    return (
      <Col>
       <Image
        preview={visible}
        style={{marginTop:20}}
        loading={'lazy'}
        src={imageLink}
        onClick={() => setVisible(true)}
      />
      </Col>
    )
}
