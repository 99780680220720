import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Modal, Row, Select } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";
import { AxiosConfig } from "../../../APIConfig";
import { authHeaders } from "../../../auth";

export const EditCategoryModal = (props) => {
  const initialContent = props.content;
  const [form] = Form.useForm();
  form.setFieldsValue({
    id: initialContent.id,
    category_Name: initialContent.category,
    is_active: initialContent.is_active === 1 ? "active" : "inactive",
  });
  const onEditCancel = () => {
    form.resetFields();
    props.onCancel();
  };
  const onEditOk = () => {
    props.onCancel();
  };
  const handleSubmit = (val) => {
    const obj = JSON.parse(JSON.stringify(val));
    obj.id = initialContent.id;
    if (val.is_active === "active") {
      obj.is_active = 1;
    } else if (val.is_active === "inactive") {
      obj.is_active = 0;
    }

    console.log("edited item to be saved", obj);
    let url = `/vouchers/category/update`;
    AxiosConfig.put(url, obj, { headers: authHeaders() })
      .then((res) => {
        if (res.data.status === "success") {
          console.log("edit category response", JSON.stringify(res));
        }

        onEditCancel();
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error, "error");
        onEditCancel();
      });
  };

  return (
    <Modal
      onOk={onEditOk}
      onCancel={onEditCancel}
      visible={props.visible}
      closable={false}
      maskClosable={false}
      footer={null}
    >
      {" "}
      <Row style={{ justifyContent: "space-between" }}>
        <Text style={{ fontFamily: "sans-serif", fontSize: 20 }}>
          Edit Category
        </Text>
        <CloseCircleOutlined style={{ fontSize: 18 }} onClick={onEditCancel} />
      </Row>
      <Divider />
      <Form form={form} layout="horizontal" onFinish={handleSubmit}>
        <Form.Item name="category_Name" label="Category">
          <Input autoComplete="off"    style={{width:350,left:10}} />
        </Form.Item>
        <Form.Item name="is_active" label="Status">
          <Select
            onChange={(e) => {
              console.log("selected", e);
            }}
            style={{width:350,left:28}}
          >
            <Select.Option value={"inactive"} key={0}>
              Inactive
            </Select.Option>
            <Select.Option value={"active"} key={1}>
              Active
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Row style={{ justifyContent: "space-evenly" }}>
            <Button type="primary" htmlType="submit">
              SAVE
            </Button>

            <Button type="primary" onClick={onEditCancel}>
              Cancel
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};
