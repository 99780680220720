import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Row } from "antd";
import Modal from "antd/lib/modal/Modal";
import Text from "antd/lib/typography/Text";
import React from "react";
import { AxiosConfig } from "../../../APIConfig";
import { authHeaders } from "../../../auth";

export const DeleteUser = (props) => {
  const [form] = Form.useForm();
  const handleSubmit = () => {
    let obj = {};
    obj.user_id = props.content.admin_id;
    let url = `/users/delete`;
    AxiosConfig.delete(url, { headers: authHeaders(), data: obj })
      .then((res) => {
        console.log("delete user response =>", res);
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error, "error");
      });
  };
  return (
    <Modal
      onCancel={props.onCancel}
      onOk={props.onOk}
      visible={props.visible}
      closable={false}
      maskClosable={false}
      footer={null}
    >
      <Row style={{ justifyContent: "space-between" }}>
        <Text style={{ fontFamily: "sans-serif", fontSize: 20 }}>
          Delete User
        </Text>
        <CloseCircleOutlined
          style={{ fontSize: 18 }}
          onClick={props.onCancel}
        />
      </Row>
      <Divider />
      <Form form={form} layout="horizontal" onFinish={handleSubmit}>
        <Text style={{ marginBottom: 30, fontSize: 16 }}>
          {" "}
          Are you sure you want to delete the user {props.content.user_name}?
        </Text>

        <Form.Item>
          <Row style={{ justifyContent: "space-evenly", marginTop: 50 }}>
            <Button type="danger" htmlType="submit">
              Delete
            </Button>

            <Button onClick={props.onCancel}>Cancel</Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};
