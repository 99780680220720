import React, { useState } from "react";
import {
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tag,
  Button,
  Divider,
  Radio,
  Upload,
  message,
  InputNumber,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

import Text from "antd/lib/typography/Text";
import { useEffect } from "react";
import { authHeaders, getDenominations } from "../../auth";
import { AxiosConfig } from "../../APIConfig";
import TextArea from "antd/lib/input/TextArea";

import { toast } from "react-toastify";
import { Denominations } from "./Denominations";
import { CloseCircleOutlined } from "@ant-design/icons";
toast.configure();

const { Option } = Select;
//props: imageLink
const Buffer = require("buffer/").Buffer;

function tagRender(props) {
  const { label, closable, onClose } = props;

  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  );
}

const displayOptions = [
  {
    value: 1,
    label: "Yes",
  },
  {
    value: 0,
    label: "No",
  },
];

const uploadButton = (
  <div
    style={{
      marginTop: 8,
      marginLeft: -30,
    }}
  >
    <PlusOutlined />
    <div>Upload</div>
  </div>
);

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const EditRewardModal = (props) => {
  const [form] = Form.useForm();
  const [geographies, setGeographies] = useState([]);
  const [categories, setCategories] = useState([]);
  const [voucherTypes, setVoucherTypes] = useState([]);
  const [display, setDisplay] = useState(
    props?.content?.is_display ? "Yes" : "No"
  );
  const showSegmentID = props?.content?.segment_id === 0 ? false : true;
  const showCapSeriesID = props?.content?.cap_series_id === "" ? false : true;

  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    if (props.content.image_path) {
      setImageList([
        {
          uid: "1",
          status: "done",
          url: props.content.image_path,
        },
      ]);
      setPreviewImage(props.content.image_path);
    } else {
      setImageList([]);
    }
  }, [props.content.image_path]);

  const [previewImage, setPreviewImage] = useState(props?.content?.image_path);
  const [previewOpen, setPreviewOpen] = useState(false);

  const editRadioVal = props?.editRadioVal;
  const setEditRadioVal = props?.setEditRadioVal;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setFormValuesInitially = () => {
    form.setFieldsValue({
      title: props.content.title,
      voucher_categories:
        props.content.vouchers_categories !== undefined
          ? props.content.vouchers_categories.map((loc) => {
              return {
                label: loc.category,
                value: loc.id,
              };
            })
          : [],
      validity: props.content.validity,
      vouchers_type: {
        label: props.content.vouchers_type?.voucher_type,
        id: props.content.vouchers_type?.id,
      },
      vouchers_geographies:
        props.content.vouchers_geographies !== undefined
          ? props.content.vouchers_geographies.map((loc) => {
              return {
                label: loc.title,
                value: loc.id,
              };
            })
          : [],
      is_active: props.content.is_active,
      featured: props.content.featured === 1 ? "Yes" : "No",
      segment_id:
        props.content.segment_id === 0 ? "" : props.content.segment_id,
      // reward_denominations: denominationList,
      description: props.content.description,
      terms_and_conditions: props.content.terms_and_conditions,
      is_display: props?.content?.is_display ? "Yes" : "No",
      reward_denominations: props?.content?.reward_denominations,
      cap_series_id: props?.content?.cap_series_id,
      points:
        props?.content?.points === null || props?.content?.points === undefined
          ? ""
          : props?.content?.points,
    });
  };

  const getVoucherTypes = () => {
    let url = `/users/vouchers/types`;
    AxiosConfig.get(url, { headers: authHeaders() })
      .then((res) => {
        // console.log("getVoucherTypes res =>", res);
        if (res?.data?.result && res.data.result?.length > 0) {
          setVoucherTypes(res.data.result);
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 420) {
          let url2 = "/refreshtoken";
          AxiosConfig.get(url2, { headers: authHeaders() })
            .then((res) => {
              window.location.reload();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
        console.log(error);
      });
  };

  const typeOptions = voucherTypes.map((item) => ({
    value: item.id,
    label: item.voucher_type,
  }));

  const featuredOptions = [
    {
      value: 1,
      label: "Yes",
    },
    {
      value: 0,
      label: "No",
    },
  ];

  useEffect(() => {
    // console.log("props content===>", props.content);
    if (props?.content?.title) {
      setFormValuesInitially();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.content?.title]);

  useEffect(() => {
    getCategories();
    getGeographies();
    getVoucherTypes();
  }, []);

  const handleCancel = () => {
    //form.resetFields();
    props.onCancel();
  };

  const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    // console.log("dataURI =>", dataURI);
    let byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0) {
      byteString = Buffer.from(dataURI.split(",")[1], "base64").toString(
        "binary"
      );
    } else {
      byteString = unescape(dataURI.split(",")[1]);
    }
    // separate out the mime component
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    return isJpgOrPng;
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = async (inputs) => {
    const { file, fileList } = inputs;
    // console.log("file =>", file, "\n", fileList);
    setImageList(fileList);

    if (fileList?.length > 0) {
      let base64Image = "";
      if (!file.url && !file.preview) {
        await getBase64(file.originFileObj).then((res) => {
          base64Image = res;
        });
      }

      const blob = dataURItoBlob(base64Image);
      const name = `image_${Math.random()}.png`;
      const imageFile = new File([blob], name);

      const imageHeader = {
        ...authHeaders(),
        "Content-Type": "multipart/form-data",
      };
      const imageFormData = new FormData();
      imageFormData.append("image", imageFile);

      const url = "/image/upload/rewards";
      AxiosConfig.post(url, imageFormData, {
        headers: imageHeader,
      })
        .then((res) => {
          // console.log("res =>", res, "\n", res.response, "\n", res.data);
          setPreviewImage(res?.data?.result?.imageUrl);
        })
        .catch(function (error) {
          console.log("error =>", error, "\n", error.response);
        });
    }
  };

  const handleSubmit = (val) => {
    // console.log("val =>", val);
    let obj = {};
    const denominations = getDenominations();
    const voucher_categories = val.voucher_categories.map((item) => {
      if (item.value && item.value !== undefined) {
        return item.value;
      } else {
        return item;
      }
    });
    const vouchers_geographies = val.vouchers_geographies.map((item) => {
      if (item.value && item.value !== undefined) {
        return item.value;
      } else {
        return item;
      }
    });
    obj.id = props.content.id;
    obj.title = val.title;
    obj.segment_id = val?.segment_id ? parseInt(val.segment_id) : 0;
    // obj.image_path = props.content.image_path;
    obj.description = val?.description ? val.description : "";
    obj.terms_and_conditions = val?.terms_and_conditions
      ? val.terms_and_conditions
      : "";
    obj.cap_series_id = val?.cap_series_id ? val.cap_series_id : "";
    obj.validity = val.validity ? val.validity : "";
    obj.geographies = vouchers_geographies ? vouchers_geographies : [];
    obj.categories = voucher_categories ? voucher_categories : [];
    obj.reward_denominations =
      editRadioVal === 1 && denominations ? JSON.parse(denominations) : [];
    obj.featured = val.featured === "Yes" || val.featured === 1 ? 1 : 0;
    obj.is_active = val.is_active;
    obj.is_display = val?.is_display ? true : false;
    obj.points = editRadioVal === 2 ? parseInt(val?.points) : null;
    obj.type_id = val?.vouchers_type?.id || val?.vouchers_type;
    obj.image_path = previewImage?.includes("https://") ? previewImage : "";

    console.log("Update obj =>", obj);
    let url = "/rewards/update";
    AxiosConfig.put(url, obj, { headers: authHeaders() })
      .then((res) => {
        toast(`${res.data.result}`);
        console.log(res.data);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(function (error) {
        toast(`${JSON.stringify(error.response)}`);
      });
  };

  // Get List of geographies
  const getGeographies = () => {
    let url = `/vouchers/geography`;
    AxiosConfig.get(url, { headers: authHeaders() })
      .then((res) => {
        if (res?.data?.result?.code === "420") {
          console.log("Need to log out here");
        }
        let locations = [];
        locations = res.data.result;

        if (Array.isArray(locations)) {
          setGeographies(
            locations?.map((row) => ({
              label: row.title,
              value: row.id,
            }))
          );
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 420) {
          let url2 = "/refreshtoken";
          AxiosConfig.get(url2, { headers: authHeaders() })
            .then((res) => {
              window.location.reload();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
        console.log(error);
      });
  };

  // Get List of Categories
  const getCategories = () => {
    let url = `/vouchers/category`;
    AxiosConfig.get(url, { headers: authHeaders() })
      .then((res) => {
        let category = res.data.result;

        setCategories(
          category.map((row) => ({
            label: row.category,
            value: row.id,
          }))
        );
        // setCategories(res.data.result);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 420) {
          let url2 = "/refreshtoken";
          AxiosConfig.get(url2, { headers: authHeaders() })
            .then((res) => {
              window.location.reload();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
        console.log(error);
      });
  };

  const imageProps = {
    listType: "picture-card",
    onPreview: (file) => handlePreview(file),
    onChange: (file) => handleChange(file),
    onRemove: (file) => {
      const index = imageList.indexOf(file);
      const newFileList = imageList.slice();
      newFileList.splice(index, 1);
      setImageList([]);
    },
    beforeUpload: (file) => beforeUpload(file),
    // {
    //   setImageList([...imageList, file]);
    //   return false;
    // },
    fileList: imageList,
  };

  //console.log("form ==>", form);

  return (
    <Modal
      visible={props.visible}
      onCancel={props.onCancel}
      width={1380}
      footer={null}
      destroyOnClose
      closable={false}
    >
      {/* {JSON.stringify(props)} */}
      <Row
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: 30,
        }}
      >
        <Text style={{ fontSize: 20 }}> Edit Reward</Text>
        <CloseCircleOutlined style={{ fontSize: 18 }} onClick={handleCancel} />
        <Divider />
      </Row>
      <Row style={{ marginBottom: 20 }}>
        <Col span={5}>
          <Upload {...imageProps}>
            {imageList.length >= 1 ? null : uploadButton}
          </Upload>
          <Modal
            visible={previewOpen}
            title={"Reward Image"}
            footer={null}
            onCancel={() => setPreviewOpen(false)}
          >
            <img
              alt="example"
              style={{
                width: "100%",
              }}
              src={previewImage}
            />
          </Modal>
        </Col>

        <Col span={18}>
          <Form
            form={form}
            onFinish={handleSubmit}
            labelCol={{
              span: 6,
            }}
          >
            {/* {JSON.stringify(props.content)} */}
            <Row style={{ justifyContent: "space-between" }}>
              <Col>
                <Form.Item
                  label="Reward Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Reward Title is mandatory",
                    },
                  ]}
                  labelAlign="left"
                >
                  <Input style={{ width: 350 }} autoComplete={"off"} />
                </Form.Item>

                <Form.Item label="Validity" name="validity" labelAlign="left">
                  <Input style={{ width: 350 }} autoComplete={"off"} />
                </Form.Item>

                <Form.Item
                  label="Reward Type"
                  name="vouchers_type"
                  rules={[
                    {
                      required: true,
                      message: "Reward Type is mandatory",
                    },
                  ]}
                  labelAlign="left"
                >
                  <Select options={typeOptions} style={{ width: 350 }} />
                </Form.Item>

                <Form.Item
                  label="Segment ID"
                  name="segment_id"
                  pattern="[0-9]*"
                  rules={[
                    {
                      message: "Segment ID must be a number",
                      pattern: new RegExp(/^[0-9]+$/),
                    },
                  ]}
                  labelAlign="left"
                >
                  <Input
                    style={{ width: 350 }}
                    autoComplete={"off"}
                    disabled={showSegmentID}
                    labelAlign="left"
                  />
                </Form.Item>
                <Form.Item
                  label="Cap Series ID"
                  name="cap_series_id"
                  labelAlign="left"
                  pattern="[0-9]*"
                  rules={[
                    {
                      message: "Cap series ID must be a number",
                      pattern: new RegExp(/^[0-9]+$/),
                    },
                  ]}
                >
                  <Input
                    style={{ width: 350 }}
                    autoComplete={"off"}
                    disabled={showCapSeriesID}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="Category"
                  name="voucher_categories"
                  labelAlign="left"
                >
                  <Select
                    showSearch={true}
                    options={categories}
                    tagRender={tagRender}
                    mode="multiple"
                    style={{ width: 350 }}
                    filterOption={(input, option) => {
                      return (
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Active/Inactive"
                  name="is_active"
                  labelAlign="left"
                >
                  <Select style={{ width: 350 }}>
                    <Option value={1}>Active</Option>
                    <Option value={0}>Inactive</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Geographies"
                  name="vouchers_geographies"
                  labelAlign="left"
                >
                  <Select
                    options={geographies}
                    tagRender={tagRender}
                    mode="multiple"
                    style={{ width: 350 }}
                    filterOption={(input, option) => {
                      return (
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  />
                </Form.Item>
                <Form.Item label="Featured" name="featured" labelAlign="left">
                  <Select options={featuredOptions} style={{ width: 350 }} />
                </Form.Item>
                <Form.Item label="Display" name="is_display" labelAlign="left">
                  <Select
                    options={displayOptions}
                    defaultValue={display}
                    onChange={(item) => setDisplay(item)}
                    style={{ width: 350 }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Radio.Group
              onChange={(e) => setEditRadioVal(e.target.value)}
              value={editRadioVal}
              style={{ paddingTop: 20, paddingBottom: 20 }}
            >
              <Radio value={1}>Denominations</Radio>
              <Radio value={2}>Direct Points</Radio>
            </Radio.Group>

            {editRadioVal === 1 && (
              <Denominations
                min={props.content.denomination_min}
                max={props.content.denomination_max}
                denominationData={props.denominationData}
              />
            )}

            {editRadioVal === 2 && (
              <Form.Item
                label="Points"
                name="points"
                style={{ paddingTop: 20, paddingLeft: 60 }}
                labelCol="left"
              >
                <InputNumber style={{ width: 350 }} autoComplete={"off"} />
              </Form.Item>
            )}

            <Col>
              <Text style={{ marginBottom: 10 }}>Terms and Conditions</Text>
              <Form.Item name="terms_and_conditions">
                <TextArea rows={6} />
              </Form.Item>
            </Col>

            <Col style={{ justifyContent: "space-between" }}>
              <Text style={{ marginBottom: 100 }}>Description</Text>
              <Form.Item name="description">
                <TextArea rows={6} />
              </Form.Item>
            </Col>

            <Form.Item>
              <Row style={{ justifyContent: "space-evenly", marginTop: 50 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: 150, height: 50 }}
                >
                  SAVE
                </Button>

                <Button
                  type="primary"
                  onClick={handleCancel}
                  style={{ width: 150, height: 50 }}
                >
                  Cancel
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};
