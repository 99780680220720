import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Modal, Row } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";

import { AxiosConfig } from "../../../APIConfig";
import { authHeaders } from "../../../auth";

export const AddCategoryModal = (props) => {
  const [form] = Form.useForm();

  const handleSubmit = (val) => {
    if (val.category_Name && val.category_Name !== undefined) {
      let obj = JSON.parse(JSON.stringify(val));
      console.log("edited item to be saved", obj);
      let url = `/vouchers/category/add`;
      AxiosConfig.post(url, obj, { headers: authHeaders() })
        .then((res) => {
          if (res.data.status === "success") {
            console.log("add category response", JSON.stringify(res));
          }

          onAddCancel();
          window.location.reload();
        })
        .catch(function (error) {
          console.log(error, "error");
          onAddCancel();
        });
    }
  };

  const onAddCancel = () => {
    form.resetFields();
    props.onCancel();
  };
  const onAddOk = () => {
    props.onOk();
  };
  return (
    <Modal
      onOk={onAddOk}
      onCancel={onAddCancel}
      visible={props.visible}
      closable={false}
      maskClosable={false}
      footer={null}
    >
      <Row style={{ justifyContent: "space-between" }}>
        <Text style={{ fontFamily: "sans-serif", fontSize: 20 }}>
          Add Category
        </Text>
        <CloseCircleOutlined style={{ fontSize: 18 }} onClick={onAddCancel} />
      </Row>
      <Divider />
      <Form form={form} layout="horizontal" onFinish={handleSubmit}>
        <Form.Item name="category_Name" label="Category">
          <Input autoComplete="off" />
        </Form.Item>

        <Form.Item>
          <Row style={{ justifyContent: "space-evenly" }}>
            <Button type="primary" htmlType="submit">
              SAVE
            </Button>

            <Button type="primary" onClick={onAddCancel}>
              Cancel
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};
