import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Switch, Table } from "antd";
import { Typography } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { AxiosConfig } from "../../../APIConfig";
import { authHeaders } from "../../../auth";
import Base from "../../Base";
import { AddUser } from "./AddUser";
import { DeleteUser } from "./DeleteModal";
import { EditUser } from "./EditUser";

export const AccessControl = () => {
  const { Title, Text } = Typography;
  const [users, setUsers] = useState([]);

  const [showAddUser, setShowAddUser] = useState(false);
  const [showEditUser, setShowEditUser] = useState(false);
  const [showDeleteUser, setShowDeleteUser] = useState(false);

  const [editContent, setEditContent] = useState({});
  const [deleteContent, setDeleteContent] = useState({});

  const [filterData, setFilterData] = useState([]);
  const [nameSearch, setNameSearch] = useState("");

  useEffect(() => {
    if (users) {
      const results = users.filter(
        (item) =>
          item.user_name.toLowerCase().includes(nameSearch.toLowerCase()) ||
          item.role.roll_name.toLowerCase().includes(nameSearch.toLowerCase())
      );
      setFilterData(results);
    }
  }, [nameSearch, users]);

  const onToggle = (data) => {
    let url = "/users/active_deactive";
    AxiosConfig.post(url, data, { headers: authHeaders() })
      .then((res) => {
        if (res.data.status === "success") {
          console.log("Toggle result", JSON.stringify(res));
          setTimeout(function () {
            window.location.reload();
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const columns = [
    {
      title: "Email",
      dataIndex: "user_name",
      key: "user_name",
      width: "30%",
    },
    {
      title: "Access",
      dataIndex: "role",
      key: "role",
      width: "30%",
      render: (item) => <Text key={item}>{item.roll_name}</Text>,
    },
    {
      title: "User Status",
      dataIndex: "is_active",
      key: "is_active",
      width: "25%",
      render: (text, record) => {
        return record.is_active ? (
          <Switch
            defaultChecked
            onChange={(val) => {
              let obj = {};
              obj.user_id = record.admin_id;
              obj.is_active = val;
              onToggle(obj);
              console.log(val);
            }}
          />
        ) : (
          <Switch
            onChange={(val) => {
              let obj = {};
              obj.user_id = record.admin_id;
              obj.is_active = val;
              console.log(val);
              onToggle(obj);
            }}
          />
        );
      },
    },
    {
      title: "  ",
      dataIndex: "operation",
      key: "operation",
      width: "15%",
      render: (text, record) => {
        return (
          <Row style={{ justifyContent: "space-evenly" }} key={record.admin_id}>
            <EditOutlined
              onClick={() => {
                setShowEditUser(true);
                setEditContent(record);
                console.log("record edit", record);
              }}
            />
            <DeleteOutlined
              onClick={() => {
                setShowDeleteUser(true);
                setDeleteContent(record);
                console.log("record delete", record);
              }}
            />
          </Row>
        );
      },
    },
  ];

  const onAddUserClick = () => {
    setShowAddUser(true);
  };

  const onAddUserCancel = () => {
    setShowAddUser(false);
  };

  const onEditUserClick = () => {
    setShowEditUser(true);
  };

  const onEditUserCancel = () => {
    setShowEditUser(false);
  };

  const onDeleteUserClick = () => {
    setShowDeleteUser(true);
  };

  const onDeleteUserCancel = () => {
    setShowDeleteUser(false);
  };

  useEffect(() => {
    const url = "/users";
    AxiosConfig.get(url, { headers: authHeaders() })
      .then((res) => {
        if (res.data.status === "success") {
          console.log("users res =>", res);
          setUsers(res.data.result);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  return (
    <Base menuKey={"7-1"}>
      <Row style={{ marginTop: 20 }}>
        <Title level={3}>
          <Text type="secondary">Access Control</Text>
        </Title>
      </Row>
      <Row className="mb-1" style={{ justifyContent: "space-between" }}>
        <Col span={14}>
          <Input
            allowClear
            onChange={(e) => {
              setNameSearch(e.target.value);
            }}
            placeholder="Search Email / Access"
          />
        </Col>
        <Button onClick={onAddUserClick}>+ Add User</Button>
        <AddUser
          visible={showAddUser}
          onOk={onAddUserClick}
          onCancel={onAddUserCancel}
        />
        <EditUser
          visible={showEditUser}
          onOk={onEditUserClick}
          onCancel={onEditUserCancel}
          content={editContent}
        />

        <DeleteUser
          visible={showDeleteUser}
          onOk={onDeleteUserClick}
          onCancel={onDeleteUserCancel}
          content={deleteContent}
        />
      </Row>

      <Table columns={columns} dataSource={filterData}></Table>
    </Base>
  );
};
