import Base from "../Base";
import { Row, Typography } from "antd";
import ReceiptTable from "./ReceiptTable";
import { useState } from "react";
import { AxiosConfig } from "../../APIConfig";
import { authHeaders } from "../../auth";
import { useEffect } from "react";
import moment from "moment";
const Flagged = () => {
  const { Title, Text } = Typography;
  const [flaggedData, setFlaggedData] = useState([]);

  const getFlaggedReceipt = () => {
    AxiosConfig.get("/receipts?is_flagged=1", { headers: authHeaders() })
      .then((res) => {
        const temp = res.data.result.data.map((v) => {
          return {
            ...v,
            receipt_number: v.receipt_number ?? "-",
            created_at: moment(v.created_at).format("DD-MM-YYYY"),
            customer_external_id: v.customer_external_id ?? "-",
            receipt_txn_date: !v.receipt_txn_date
              ? "No Date"
              : moment(v.receipt_txn_date).format("DD-MM-YYYY"),
            ocr_confident_level: v.ocr_confident_level ?? 0,
            remarks: v.remarks ?? "-",
          };
        });
        setFlaggedData(temp);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getFlaggedReceipt();
  }, []);

  return (
    <Base menuKey="1">
      <Row style={{ marginTop: 20 }}>
        <Title level={3}>
          <Text type="secondary">Flagged Receipts</Text>
        </Title>
      </Row>
      <ReceiptTable data={flaggedData} />
    </Base>
  );
};

export default Flagged;
