import { Button, Col, Input, Row, Spin, Table } from "antd";
import React from "react";
import Base from "../Base";
import { Typography } from "antd";
import { authHeaders } from "../../auth";
import { AxiosConfig } from "../../APIConfig";
import { useHistory } from "react-router-dom";
import { useState } from "react";

export const Search = (props) => {
  const { Title, Text } = Typography;

  const history = useHistory();
  const [mobileNumber, setMobileNumber] = useState();

  const [receipts, setReceipts] = useState(null);
  const [userDetails, setUserDetails] = useState(null);

  const [searchFailure, setSearchFailure] = useState(false);
  const [loading, setLoading] = useState(false);

  console.log("receipts:", JSON.stringify(receipts));
  console.log("user details:", userDetails);

  const onView = (receipt_id) => {
    history.push({ pathname: `/details/${receipt_id}` });
  };

  const columns = [
    {
      title: "Submission Date",
      dataIndex: "created_at",
      render: (item) => {
        return <Text>{item.substring(0, 10)}</Text>;
      },
    },
    {
      title: "Receipt Date",
      dataIndex: "receipt_txn_date",
    },
    {
      title: "Status",
      dataIndex: "processing_master_status",
      render: (item) => {
        return <Text>{item.status}</Text>;
      },
    },
    {
      title: "OCR Confidence",
      dataIndex: "ocr_confident_level",
      render: (item) => {
        return <Text>{JSON.stringify(item) + "%"}</Text>;
      },
    },

    {
      title: "Points Awarded",
      dataIndex: "points_awarded",
    },

    {
      title: "  ",
      dataIndex: "operation",
      key: "operation",
      render: (text, record) => {
        return (
          <Row style={{ justifyContent: "space-between", width: 40 }}>
            <Button
              onClick={() => {
                onView(record.receipt_id);
              }}
            >
              {" "}
              View
            </Button>
          </Row>
        );
      },
    },
  ];

  const reload = () => {
    window.location.reload();
  };

  const search = () => {
    if (mobileNumber.length === 10 || mobileNumber.length === 12) {
      let url = `/receipts/search?customer_external_id=` + mobileNumber;
      console.log("api calling url", url);
      setLoading(true);
      AxiosConfig.get(url, { headers: authHeaders() })
        .then((res) => {
          console.log("search response", res);
          if (res.data.status === "success") {
            if (res.data.result.code === 200) {
              setLoading(false);
              setSearchFailure(false);
              setReceipts(res.data.result.data.receipts);
              setUserDetails(res.data.result.data.capillaryResponse);
            }
          } else {
            setLoading(false);
            setSearchFailure(true);
          }
        })
        .catch(function (error) {
          setLoading(false);
          setSearchFailure(true);
          console.log(error);
        });
    } else {
      setSearchFailure(true);
    }
  };
  return (
    <Base menuKey={"1-4"}>
      <Row style={{ marginTop: 20 }}>
        <Title level={3}>
          <Text type="secondary">Search</Text>
        </Title>
      </Row>

      <Col span={12}>
        <Row style={{ justifyContent: "space-between" }}>
          <Col span={12}>
            <Input
              placeholder={"Search Mobile Number"}
              defaultValue={mobileNumber}
              onChange={(e) => {
                console.log("inside search box", e.target.value);
                setMobileNumber(e.target.value);
              }}
            />
          </Col>
          <Col span={12}>
            <Row style={{ justifyContent: "space-evenly" }}>
              <Button onClick={search} style={{ width: 120 }}>
                Search
              </Button>
              <Button onClick={reload} style={{ width: 120 }}>
                Refresh
              </Button>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col style={{ marginTop: 30 }}>
        {userDetails && receipts && !searchFailure && (
          <Col>
            {loading && (
              <Row
                style={{
                  height: 500,
                  width: 1200,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spin spinning={loading} delay={500} />
              </Row>
            )}
            {!loading && (
              <Row>
                <Col span={22}>
                  <Row
                    style={{
                      backgroundColor: "#e1e7ec",
                      marginBottom: 20,
                      height: 76,
                    }}
                  >
                    {/* Column 1*/}
                    <Col span={8}>
                      <Row
                        style={{
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <Row
                          style={{
                            marginBottom: 10,
                            marginTop: 10,
                            marginLeft: 20,
                          }}
                        >
                          Name : {userDetails.firstname}{" "}
                        </Row>
                        <Row style={{ marginLeft: 20 }}>
                          Mobile : {userDetails.mobile}{" "}
                        </Row>
                      </Row>
                    </Col>
                    {/* Column 2*/}
                    <Col span={8}>
                      <Row
                        style={{
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <Row
                          style={{
                            marginBottom: 10,
                            marginTop: 10,
                            marginLeft: 20,
                          }}
                        >
                          Member Since :{" "}
                          {userDetails.registered_on.substring(0, 10)}
                        </Row>
                        <Row style={{ marginLeft: 20 }}>
                          Lifetime Points : {userDetails.lifetime_points}
                        </Row>
                      </Row>
                    </Col>
                    {/* Column 3*/}
                    <Col span={8}>
                      <Row
                        style={{
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <Row
                          style={{
                            marginBottom: 10,
                            marginTop: 10,
                            marginLeft: 20,
                          }}
                        >
                          Current Points : {userDetails.loyalty_points}
                        </Row>
                        <Row style={{ marginLeft: 20 }}>
                          MemberShip Status : {userDetails.current_slab}
                        </Row>
                      </Row>
                    </Col>
                  </Row>
                  <Table columns={columns} dataSource={receipts} />
                </Col>
              </Row>
            )}
          </Col>
        )}
        {
          ((mobileNumber===undefined||mobileNumber==='') && !searchFailure)  && (
            <Col span={22}>
              <Row
                style={{
                  justifyContent: "center",
                  height: 200,
                  alignItems: "center",
                  backgroundColor: "rgba(245, 245, 245, 1)",
                }}
              >
                <Text style={{fontSize:14,color:'gray'}}> Please type a phone number to begin </Text>
              </Row>
            </Col>
          )
        }
        {searchFailure && (
          <Col span={22}>
            <Row
              style={{
                justifyContent: "center",
                height: 200,
                alignItems: "center",
                backgroundColor: "rgba(245, 245, 245, 1)",
              }}
            >
              <Text style={{fontSize:14,color:'gray'}}> No Customer found </Text>
            </Row>
          </Col>
        )}
      </Col>
    </Base>
  );
};
