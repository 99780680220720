import React, { useState } from "react";
import download from "../../assets/download.png";
import { authHeaders } from "../../auth";
import ReportsModal from "./ReportsModal";
import { AxiosConfig } from "../../APIConfig";
require("../../styles/Base.scss");

const ReportsMenu = () => {

  const [showModal, setShowModal] = useState(false);
  const [reportType, setReportType] = useState(null);

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleDownloadBeerRatingReport = () => {
    console.log("authHeaders ::", authHeaders());

    const url = "/report/variant";
    AxiosConfig.post(url, null,{ headers: authHeaders()})
      .then((response) => {
        console.log(response, "===>priya");
        const { data } = response;

        let formattedData = "";
        if (data && data.result.length > 0) {
          formattedData += "Variant Name,Avg. rating\n";
          data.result.forEach((row) => {
            const variantName = row.beer_name || "NA";
            const avgRatings = row.avg_ratings || "NA";

            formattedData += `${variantName},${avgRatings}\n`;
          });
        }

        const downloadLink = document.createElement("a");
        const blob = new Blob([formattedData], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.setAttribute("download", "BeerRatingReport.csv");
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      })
      .catch((error) => {
        console.log("error ::", error);
      });
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          margin: "0 15px",
          alignItems: "center",
          textAlign: "center",
         marginLeft: "8px",
        }}
      >
        <p style={{ margin: "auto 14%" }}>Bill Scan</p>
        <img
        alt="billDownload"
          src={download}
          style={{ height: "30px", width: "30px", cursor: "pointer" }}
          onClick={() => {
            setReportType("bill_scan");
            setShowModal(true);
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          margin: "0 15px",
          alignItems: "center",
          textAlign: "center",
          marginLeft: "8px",
          marginTop:"20px"
        }}
      >
        <p style={{ margin: "auto 14%" }}>Beer Rating</p>
        <img
        alt="beerDownload"
          src={download}
          style={{ height: "30px", width: "30px", cursor:"pointer" }}
          onClick={() => {
            // setReportType("beer_rating");
            // setShowModal(true);
            handleDownloadBeerRatingReport();
          }}
        />
      </div>
      {showModal ? (
        <ReportsModal
          reportType={reportType}
          showModal={showModal}
          handleCancel={() => handleCancel(false)}
        />
      ) : null}
    </div>
  );
};

export default ReportsMenu;
