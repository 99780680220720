import { authHeaders} from "../auth";
import { AxiosConfig } from "../APIConfig";
export const login = async (data) =>
  AxiosConfig.post("/login", data, {
    headers: authHeaders(),
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });

export const logout = async()=>{
  sessionStorage.removeItem("token");
  localStorage.removeItem("admin");
}