import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Modal, Row, Select } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";
import { AxiosConfig } from "../../../APIConfig";
import { authHeaders } from "../../../auth";
const { Option } = Select;
export const EditLocationModal = (props) => {
  const initialContent = props.content;
  console.log("inital content Location", JSON.stringify(initialContent));
  const [form] = Form.useForm();
  form.setFieldsValue({
    name: initialContent.name,
    location_type: initialContent.location_type,
    alias: initialContent.alias,
  });

  const geoOptions = [
    {
      value: "country",
    },
    {
      value: "state",
    },
    {
      value: "city",
    },
  ];
  const onEditCancel = () => {
    form.resetFields();
    props.onCancel();
  };
  const onEditOk = () => {
    props.onCancel();
  };
  const handleSubmit = (val) => {
    const obj = JSON.parse(JSON.stringify(val));
    obj.id=initialContent.id
    console.log("edited location", obj);
    

    console.log("edited location to be saved", obj);
    let url = `/vouchers/location/update`;
    AxiosConfig.put(url, obj, { headers: authHeaders() })
      .then((res) => {
        if (res.data.status === "success") {
          console.log("edit category response", JSON.stringify(res));
        }

        onEditCancel();
        window.location.reload()
      })
      .catch(function (error) {
        console.log(error, "error");
        onEditCancel();
      });
  };

  return (
    <Modal
      onOk={onEditOk}
      onCancel={onEditCancel}
      visible={props.visible}
      closable={false}
      maskClosable={false}
      footer={null}
    >
      <Row style={{ justifyContent: "space-between" }}>
        <Text style={{ fontFamily: "sans-serif", fontSize: 20 }}>
          Edit Location
        </Text>
        <CloseCircleOutlined style={{ fontSize: 18 }} onClick={onEditCancel} />
      </Row>
      <Divider />
      <Form form={form} layout="horizontal" onFinish={handleSubmit}>
        <Form.Item name="name" label="Name">
          <Input autoComplete="off" style={{width:400}}/>
        </Form.Item>

        <Form.Item name="location_type" label="Type">
          <Select style={{left:8,width:400}}>
            {geoOptions.map((item) => {
              return <Option value={item.value}>{item.value}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item name="alias" label="Alias">
          <Input autoComplete="off" style={{width:400,left:10}}/>
        </Form.Item>
        <Form.Item>
          <Row style={{ justifyContent: "space-evenly" }}>
            <Button type="primary" htmlType="submit">
              SAVE
            </Button>

            <Button type="primary" onClick={onEditCancel}>
              Cancel
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};
