import { createAsyncThunk } from '@reduxjs/toolkit'
import ApiConfig2 from '../ApiConfigV2';

export const getOTP = createAsyncThunk(
    'getOTP',
    async (request, { rejectWithValue }) => {
        try {
            const { data } = await ApiConfig2.post(`user/send-otp`, request)

            const resMsgType =
                data.status.success === true ? 'success' : 'error'
            const resMsg = data.result
                ? 'OTP fetched'
                : data.error
                ? data.status.message
                : ''
            if (resMsgType === 'success') {
                return data.result
            } else if (resMsgType === 'error') {
                return rejectWithValue(resMsg)
            }
        } catch (error) {
            return error
        }
    }
)

export const deleteUser = createAsyncThunk(
    'deleteUser',
    async (request, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await ApiConfig2.post(`user/initiate-deletion`, request)
            const resMsgType =
                data.status === true ? 'success' : 'error'

            const resMsg = data.result
                ? 'Account initiated to delete'
                : data.error
                ? data.status.message
                : ''
            

            if (resMsgType === 'success') {
                return data
            } else if (resMsgType === 'error') {
                return rejectWithValue(resMsg)
            }
        } catch (error) {
            if (error.message.includes("429")) {
                return "Kindly hang on! We’ve already received your request for account deletion.";
            } else {
                const errorResponse = error?.response?.data?.message;
                return errorResponse;
            }
        }
    }
)