import { useHistory } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { userLogout } from "../slices/auth";
require("../styles/Logout.scss");

const Logout = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const logout = () => {
        dispatch(userLogout())
                          .unwrap()
                          .then(() => {
                            history.push("/");
                          })
                          .catch((err) => {
                            console.log(err);
                          });
      
    };
    return (
        <LogoutOutlined
            title="Log Out"
            className="logout-icon"
            onClick={logout}
        />
    );
};

export default Logout;
