import { Button, Card, Col, Row, Table } from "antd";
import React, { useEffect } from "react";
import { authHeaders, getrnum, getstore, setSaveButtonClicked } from "../../../auth";
import { AxiosConfig } from "../../../APIConfig";
import { useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Text from "antd/lib/typography/Text";
import { EditItem } from "./EditItem";
import { AddItem } from "./AddItem";
import { ReceiptLogs } from "./ReceiptLogs";
import { AwardPoints } from "./AwardPoints";
import { toast } from "react-toastify";
import axios from "axios";
toast.configure();
//Table of Line Items
export const ItemTable = (props) => {
  const [items, setItems] = useState([]);
  const rnum = getrnum();

  const [showEditModal, setShowEditModal] = useState(false);
  const [editContent, setEditContent] = useState({});
  const [addItemModal, setAddItemModal] = useState(false);

  const onSave = () => {
    /* adding edited list of line items and edited receipt details  in one function call */
    const rnum = getrnum();

    const receiptObject = getstore(); //Edited receipt_details
    const receiptDetails = {
      ...receiptObject,
      receipt_id: typeof rnum === "string" ? parseInt(rnum) : rnum,
      receipt_number: receiptObject.receipt_number
        ? `${receiptObject.receipt_number}`
        : "",
      receipt_txn_date:
        receiptObject.receipt_txn_date === "dd/mm/yyyy"
          ? "null"
          : receiptObject.receipt_txn_date,
      gst_number: receiptObject.gst_number ? receiptObject.gst_number : "",
      store_name:receiptObject.store_name?receiptObject.store_name:""
    };

    const fieldItems = []; //holds the list of line items for /product/add

    items.forEach((item) => {
      let obj = {};
      obj.pack_type_id =
        item.pack_type_id && !isNaN(item.pack_type_id) ? item.pack_type_id : 0;
      obj.quantity = item.quantity && !isNaN(item.quantity) ? item.quantity : 0;
      obj.aliases = item.aliases ? item.aliases : "";
      obj.product_id =
        item.product_id && !isNaN(item.product_id) ? item.product_id : 0;
      obj.amount = item.amount && !isNaN(item.amount) ? item.amount : 0;
      fieldItems.push(obj);
    });

    const reqBody = {
      receipt_id: parseInt(rnum),
      products: fieldItems,
    };

    console.log("products request Body", JSON.stringify(reqBody));
    console.log("Receipts request body", JSON.stringify(receiptDetails));
   

    // alert(JSON.stringify(receiptDetails));
    
    const url = "/products/add";
    const url1 = "/receipts/edit";


 
    if(receiptDetails.taproom_bill && receiptDetails.taproom_bill!==undefined){
    const request1=AxiosConfig.post(url, reqBody, { headers: authHeaders() })
    const request2= AxiosConfig.post(url1, receiptDetails, { headers: authHeaders() })
     axios.all([request1,request2]).then(axios.spread((result1,result2)=>{
      if(result1.data.status==='success' && result2.data.status==='success'){
        setSaveButtonClicked()
        toast('Line Items and Receipt Details Saved Successfully.Please ensure the correct remark has chosen')
      }
 
    })).catch(error=>{toast(error.response.data.message)})

    }
    else{
      toast('Please check if the receipt is a Taproom Bill')
    }
   
  };

  const deleteItem = (id) => {
    const rows = items.filter((item) => {
      return item.product_id !== id;
    });
    setItems(rows);
  };

  const onEditOk = (item) => {
    setShowEditModal(false);
    console.log("item in onEditOk to update", JSON.stringify(item));
    if (item.previous_id) {
      const updatedData = items.map((x) =>
        x.product_id === item.previous_id ? { ...item } : x
      );
      setItems([...updatedData]);
    }
  };

  const onEditCancel = () => {
    setShowEditModal(false);
  };

  const onAddOk = (item) => {
    setAddItemModal(false);

    const updatedData = items.filter((obj) => {
      return (
        obj.product_id === item.product_id &&
        obj.pack_type_id === item.pack_type_id
      );
    });
    if (updatedData.length > 0) {
      alert("Duplicate Item: Item having this ID has been previously added");
    } else if (updatedData.length <= 0) {
      setItems([...items, item]);
    }
  };
  const onAddCancel = () => {
    setAddItemModal(false);
  };

  const addItem = () => {
    setAddItemModal(true);
  };

  useEffect(() => {
    const url = `/receipts/item_list?receipt_id=${rnum}`;
    console.log("receipt url:", url);
    AxiosConfig.get(url, { headers: authHeaders() })
      .then((response) => {
        if (response.data && response.data.status === "success") {
          const itemList = [];
          response.data.result.data.forEach((item) => {
            let obj = {};
            obj.product_id = item.pack_type?.product?.product_id;
            obj.pack_type_id = item.pack_type_id;
            obj.product_name = item.pack_type?.product?.product_name;
            obj.pack_type_description = item.pack_type?.pack_type_description;
            obj.aliases = item.pack_type?.product?.product_aliases
              ?.map((x) => x.alias_name)
              .join(",");
            obj.amount = item.total_price;
            obj.quantity = item.quantity;

            itemList.push(obj);
          });
          setItems(itemList);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [rnum]);

  const column = [
    {
      title: "Product ID",
      dataIndex: "product_id",
      key: "product_id",
    },
    {
      title: "Brand Name",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "Pack Type",
      dataIndex: "pack_type_description",
      key: "pack_type_description",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Alias",
      dataIndex: "aliases",
      key: "aliases",
      render: (item) => {
        return (
          <Text>
            {item && item.length && item.length > 10
              ? item.substring(0, 10) + "..."
              : item}
          </Text>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },

    {
      title: "  ",
      dataIndex: "operation",
      key: "operation",
      render: (text, record) => {
        return (
          <Row style={{ justifyContent: "space-between", width: 40 }}>
            <EditOutlined
              onClick={(val) => {
                setShowEditModal(true);
                setEditContent(record);
              }}
            />
            <DeleteOutlined
              onClick={() => {
                deleteItem(record.product_id);
              }}
            />
          </Row>
        );
      },
    },
  ];
  return (
    <Card>
      <Row
        style={{
          marginBottom: 20,
          justifyContent: "space-between",
          paddingLeft: 20,
        }}
      >
        <Text style={{ textTransform: "uppercase", fontSize: 15 }}>
          Line Items
        </Text>
        <Row>
          <Button onClick={addItem}>+ Add Item</Button>
        </Row>
      </Row>
      <Col style={{ marginBottom: 30 }}>
        <EditItem
          onOk={onEditOk}
          onCancel={onEditCancel}
          content={editContent}
          items={items}
          setItems={setItems}
          visible={showEditModal}
        />
        <AddItem
          onOk={onAddOk}
          onCancel={onAddCancel}
          items={items}
          setItems={setItems}
          visible={addItemModal}
        />

        {items.length > 0 && <Table columns={column} dataSource={items} />}

        <Row
          style={{
            justifyContent: "space-between",
            paddingTop: 20,
            marginBottom: 50,
          }}
        >
          <Button
            onClick={onSave}
            type="primary"
            ghost
            style={{
              marginRight: 10,
              width: 100,
            }}
          >
            Save 
          </Button>
          <AwardPoints ocstat={props.ocstat} status={props.status} />
        </Row>
      </Col>
      <Col style={{ paddingLeft: 20, marginBottom: 20 }}>
        <Text style={{ textTransform: "uppercase" }}>Receipt Status</Text>
      </Col>

      <ReceiptLogs />
    </Card>
  );
};
