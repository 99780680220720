import axios from "axios";
import { toast } from "react-toastify";
toast.configure();

const url = process.env.REACT_APP_BASE_URL;

const AxiosConfig = axios.create({
  baseURL: url,
});

AxiosConfig.interceptors.response.use(
  function (response) {
    if (
      response.data &&
      response.data.result &&
      response.data.result.code &&
      response.data.result.code === "420"
    ) {
      console.log("Logging out the user");
      setTimeout(() => {
        toast(`Session Expired. Logging you out`);
        sessionStorage.removeItem("token");
        localStorage.removeItem("admin");
        window.location.href = "/";
      }, 2000);
    }
    return response;
  },
  function (error) {
    console.log("Remove interceptors error", error);
    return error;
    // return Promise.reject(error);
  }
);

export { AxiosConfig };
