import React, { Fragment, useState } from "react";
import "./deleteAccount.scss";
import { deleteUser } from "../../DeleteAccount/deleteAccountAction";
import { useDispatch } from "react-redux";
import Logo from "../../../src/assets/logo.png";
import DeleteLogo from "../../../src/assets/delete.png";
import congratulation from "../../../src/assets/gif/congratulations_monkey_gif.gif";

const Modal = ({ isOpen, image, text, tittle, onOk }) => {
  return (
    <>
      {isOpen && (
        <div className="confirmation-modal">
          <div className="modal-content">
            <img src={image} className="delete-image" alt="delete" />
            <p className="tittle-text">{tittle}</p>
            <p>{text}</p>
            <button onClick={onOk} className="okayButton">
              Delete Another Account
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const DeleteAccount = () => {
  const [confirmation, setConfirmation] = useState("");
  const [error, setError] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const dispatch = useDispatch();

  const MandatoryLabel = ({ label }) => {
    return (
      <div className="phone-number">
        <span>{label}</span>
        <span style={{ color: "red" }}>*</span>
      </div>
    );
  };

  const handlePhoneNumberChange = (e) => {
    const input = e.target.value;
    if (input === "" || /^[6789]\d{0,9}$/.test(input)) {
      setConfirmation(input);
      setError("");
      if (input.length === 10) {
        e.target.blur();
      }
    } else {
      setError(
        "Phone number must start with 6, 7, 8, or 9 and can only be 10 digits"
      );
    }
  };

  const handleDelete = (e) => {
    setShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    const phoneNumber = "91" + confirmation;
    if (confirmation.length === 10) {
      dispatch(
        deleteUser({
          mobile: phoneNumber,
        })
      ).then((response) => {
        const errorMessage = response?.payload;
        if (response.payload && response.payload.code === 200) {
          setShowSuccessMessage(true);
        } else {
          setShowConfirmationModal(false);
          if (response?.type === "deleteUser/rejected") {
            setError(
              "This number is already deleted or not registered in All Stars."
            );
          } else {
            setError(errorMessage);
          }
        }
        if (response.payload && response.payload.code === 400) {
          setError(response.payload.message);
        }
      });
    } else {
      setShowConfirmationModal(false);
      setError("Invalid phone number");
    }
  };

  const handleCancelDelete = () => {
    setShowConfirmationModal(false);
  };

  const successOk = () => {
    window.location.reload();
  };

  return (
    <Fragment>
      <div className="screen">
        <div className="delete-account">
          <img src={Logo} alt="All Stars Logo" className="logo" />
          <h1 className="delete-title">Delete Account</h1>
          <p className="main-text">
            We are sorry to see you go. To confirm account deletion, enter
            details below:
          </p>
        </div>
        <div className="Form">
          <div className="entire-input">
            <div className="phone-number">
              <MandatoryLabel label="Please enter your mobile number to get started"></MandatoryLabel>
            </div>
            <div className={`phone-number-input ${error ? "error" : ""}`}>
              <input
                type="number"
                placeholder="Enter registered phone number"
                value={confirmation}
                onChange={handlePhoneNumberChange}
              />
            </div>
          </div>

          {error && <div className="error-message">{error}</div>}

          <div className="button-allignment">
            <button
               className={`action-button ${confirmation.length !== 10 ? "disabled" : ""}`}
              onClick={handleDelete}
              disabled={confirmation.length !== 10} 
            >
              Delete Account
            </button>
          </div>
          {showConfirmationModal && (
            <div className="confirmation-modal">
              <div className="modal-content">
                {showSuccessMessage ? (
                  <Fragment>
                    <Modal
                      isOpen={showConfirmationModal}
                      image={congratulation}
                      tittle={"We’ve successfully received your request to delete Bira 91 MakePlay Account"}
                      text={
                        "Deletion may take upto 72hours. Please bear with us."
                      }
                      onOk={successOk}
                    />
                  </Fragment>
                ) : (
                  <Fragment>
                    <img
                      src={DeleteLogo}
                      className="delete-image"
                      alt="delete"
                    />
                    <p>Are you sure you want to delete your account?</p>
                    <div className="modal-buttons">
                      <button onClick={handleConfirmDelete}>Delete</button>
                      <button onClick={handleCancelDelete}>Cancel</button>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default DeleteAccount;
