import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
} from "antd";
import React, { useEffect } from "react";
import { authHeaders } from "../../../auth";
import { AxiosConfig } from "../../../APIConfig";
import { useState } from "react";
import Text from "antd/lib/typography/Text";
import { CloseCircleOutlined } from "@ant-design/icons";
const { Option } = Select;

//Modal to Edit Items in the Line List
export const AddItem = (props) => {
  const [form] = Form.useForm();

  const [, setLoading] = useState(false);

  const [products, setProducts] = useState([]);
  const [packTypes, setPackTypes] = useState([]);

  const [producName, setProductName] = useState("");
  const [packTypeName, setPackTypeName] = useState("");
  const [displayMessage, setDisplayMessage] = useState(false);

  const [, setProductId] = useState(0);
  const [, setPackTypeId] = useState(0);

  // console.log("product name", producName);

  const handleSubmit = (val) => {
    val.product_name = producName;
    val.pack_type_description = packTypeName;
    val.pack_type_id = parseInt(val.pack_type_id);
    val.product_id = parseInt(val.product_id);

    props.onOk(val);
    setDisplayMessage(false);
    form.resetFields();
  };

  const handleCancel = () => {
    form.resetFields();
    props.onCancel();
    setDisplayMessage(false);
  };

  const handleProductSelection = (key, val) => {
    setProductId(parseInt(key));
    setDisplayMessage(true)
    setProductName(val.children);
    //Fetch all pack types associated with the product Name
    let url1 = "/packTypes?product_id=" + key;
    AxiosConfig.get(url1, { headers: authHeaders() })
      .then((res) => {
        if (res.data.status === "success") {
          setPackTypes(res.data.result);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const handlePackTypeSelection = (key, val) => {
    setPackTypeName(val.children);
    setPackTypeId(parseInt(key));
    setDisplayMessage(false);
  };

  //fetch all products for the product name Selector
  useEffect(() => {
    let url1 = `/products`;
    setLoading(true);
    AxiosConfig.get(url1, { headers: authHeaders() })
      .then((res) => {
        if (res.data.status === "success") {
          setProducts(res.data.result);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }, []);

  //upon clicking ok in the modal after editing the item
  const onEditItemOk = () => {
    props.onOk();
  };
  return (
    <Modal
      onCancel={props.onCancel}
      visible={props.visible}
      onOk={onEditItemOk}
      maskClosable={false}
      footer={null}
      closable={false}
    >
      <Col style={{ paddingRight: 10, paddingTop: 20 }}>
        <Row
          style={{ flexDirection: "column", justifyContent: "space-between" }}
        >
          <Row style={{ justifyContent: "space-between" }}>
            <Text style={{ fontFamily: "sans-serif", fontSize: 20 }}>
              Add Line Item
            </Text>
            <CloseCircleOutlined
              style={{ fontSize: 18 }}
              onClick={handleCancel}
            />
          </Row>
        </Row>

        <Divider />
        <Row
          style={{ flexDirection: "column", justifyContent: "space-between" }}
        >
          {JSON.stringify(props.content)}
          <Form form={form} layout="horizontal" onFinish={handleSubmit}>
            <Form.Item label="Brand Name" name="product_id">
              <Select
                onSelect={handleProductSelection}
                style={{ width: 360, marginLeft: 20 }}
              >
                {products.map((item) => {
                  return (
                    <Option key={item.product_id}>{item.product_name}</Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Col
              style={{
                color: "red",
                left: 115,
                marginBottom: displayMessage ? 30 : 0,
              }}
            >
              {displayMessage && (
                <Text style={{ color: "red", fontSize: 13 }}>
                  Please pick the Pack Type for the producted chosen
                </Text>
              )}
            </Col>

            <Form.Item label="Pack Type" name="pack_type_id">
              <Select
                onSelect={handlePackTypeSelection}
                style={{ width: 360, marginLeft: 33 }}
              >
                {packTypes.map((item) => {
                  return (
                    <Option key={item.pack_type_id}>
                      {item.pack_type_description}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item label="Quantity" name="quantity">
              <InputNumber style={{ width: 360, marginLeft: 46 }} />
            </Form.Item>

            <Form.Item label="Aliases" name="aliases">
              <Input
                autoComplete="off"
                style={{ width: 360, marginLeft: 50 }}
              />
            </Form.Item>

            <Form.Item label="Amount" name="amount">
              <InputNumber
                style={{ width: 360, marginLeft: 50, marginBottom: 10 }}
              />
            </Form.Item>

            <Form.Item>
              <Row style={{ justifyContent: "space-evenly" }}>
                <Button type="primary" htmlType="submit">
                  SAVE
                </Button>

                <Button type="primary" onClick={handleCancel}>
                  Cancel
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Row>
      </Col>
    </Modal>
  );
};
