import React, { useState } from "react";
import {
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tag,
  Button,
  Divider,
  Radio,
  Upload,
  message,
  InputNumber,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

import Text from "antd/lib/typography/Text";
import { useEffect } from "react";
import { authHeaders, getDenominations } from "../../auth";
import { AxiosConfig } from "../../APIConfig";

import { toast } from "react-toastify";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Denominations } from "./Denominations";

toast.configure();

const { Option } = Select;
const { TextArea } = Input;

function tagRender(props) {
  const { label, closable, onClose } = props;

  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  );
}

const displayOptions = [
  {
    value: 1,
    label: "Yes",
  },
  {
    value: 0,
    label: "No",
  },
];

const uploadButton = (
  <div
    style={{
      marginTop: 8,
      marginLeft: -30,
    }}
  >
    <PlusOutlined />
    <div>Upload</div>
  </div>
);

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const AddRewardModal = (props) => {
  const [form] = Form.useForm();
  const [geographies, setGeographies] = useState([]);
  const [categories, setCategories] = useState([]);
  const [value, setValue] = useState(0);
  const [display, setDisplay] = useState(displayOptions[1].value);
  const [imageList, setImageList] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [voucherTypes, setVoucherTypes] = useState([]);

  const Buffer = require("buffer/").Buffer;

  const getVoucherTypes = () => {
    let url = `/users/vouchers/types`;
    AxiosConfig.get(url, { headers: authHeaders() })
      .then((res) => {
        // console.log("getVoucherTypes res =>", res);
        if (res?.data?.result && res.data.result?.length > 0) {
          setVoucherTypes(res.data.result);
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 420) {
          let url2 = "/refreshtoken";
          AxiosConfig.get(url2, { headers: authHeaders() })
            .then((res) => {
              window.location.reload();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
        console.log(error);
      });
  };

  const typeOptions = voucherTypes.map((item) => ({
    value: item.id,
    label: item.voucher_type,
  }));

  const featuredOptions = [
    {
      value: 1,
      label: "Yes",
    },
    {
      value: 0,
      label: "No",
    },
  ];

  useEffect(() => {
    getCategories();
    getGeographies();
    getVoucherTypes();
  }, []);

  const handleCancel = () => {
    form.resetFields();
    props.onCancel();
  };

  const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    // console.log("dataURI =>", dataURI);
    let byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0) {
      byteString = Buffer.from(dataURI.split(",")[1], "base64").toString(
        "binary"
      );
    } else {
      byteString = unescape(dataURI.split(",")[1]);
    }
    // separate out the mime component
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    return isJpgOrPng;
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = async (inputs) => {
    const { file, fileList } = inputs;
    // console.log("file =>", file, "\n", fileList);
    setImageList(fileList);

    if (fileList?.length > 0) {
      let base64Image = "";
      if (!file.url && !file.preview) {
        await getBase64(file.originFileObj).then((res) => {
          base64Image = res;
        });
      }

      const blob = dataURItoBlob(base64Image);
      const name = `image_${Math.random()}.png`;
      const imageFile = new File([blob], name);

      const imageHeader = {
        ...authHeaders(),
        "Content-Type": "multipart/form-data",
      };
      const imageFormData = new FormData();
      imageFormData.append("image", imageFile);

      const url = "/image/upload/rewards";
      AxiosConfig.post(url, imageFormData, {
        headers: imageHeader,
      })
        .then((res) => {
          // console.log("res =>", res, "\n", res.response, "\n", res.data);
          setPreviewImage(res?.data?.result?.imageUrl);
        })
        .catch(function (error) {
          console.log("error =>", error, "\n", error.response);
        });
    }
  };

  const handleSubmit = (val) => {
    // console.log("val =>", val);
    const denominations = getDenominations();

    let sku = "";

    if (val?.vouchers_type === 1) {
      sku = "Internal";
    } else if (val?.vouchers_type === 2) {
      sku = "Offers";
    }

    const data = {
      title: val?.title,
      validity: val?.validity ? val.validity : "",
      segment_id: val?.segment_id ? parseInt(val.segment_id) : 0,
      cap_series_id: val?.cap_series_id ? val.cap_series_id : "",
      is_active: val?.is_active,
      featured: val?.featured,
      terms_and_conditions: val?.terms_and_conditions
        ? val.terms_and_conditions
        : "",
      description: val?.description ? val.description : "",
      image_path: previewImage?.includes("https://") ? previewImage : "",
      type_id: val?.vouchers_type,
      sku: sku,
      is_display: display === 1 ? true : false,
      categories: val?.voucher_categories ? val.voucher_categories : [],
      geographies: val?.vouchers_geographies ? val.vouchers_geographies : [],
      reward_denominations:
        denominations && value === 1 ? JSON.parse(denominations) : [],
      points: value === 2 ? parseInt(val?.points) : null,
    };

    // console.log("Add reward data =>", data);

    let url = "/rewards/add";
    AxiosConfig.post(url, data, { headers: authHeaders() })
      .then((res) => {
        console.log("res =>", res.response);
        if (res?.data?.result) {
          toast(`${res?.data?.result}`);
          setTimeout(() => {
            window.location.reload();
          }, 200);
        } else {
          if (res?.response?.status === 400) {
            const error = res?.response?.data?.message;
            toast(`${error}`);
          }
        }
      })
      .catch(function (error) {
        console.log("error =>", error, "\n", error.response);
        // toast(`${JSON.stringify(error.response)}`);
      });
  };

  // Get List of geographies
  const getGeographies = () => {
    let url = `/vouchers/geography`;
    AxiosConfig.get(url, { headers: authHeaders() })
      .then((res) => {
        console.log("getGeographies res =>", res);
        if (res?.data?.result?.code === "420") {
          console.log("Need to log out here");
        }
        let locations = [];
        locations = res.data.result;
        if (Array.isArray(locations)) {
          setGeographies(
            locations?.map((row) => ({
              label: row?.title,
              value: row?.id,
            }))
          );
        }
      })
      .catch(function (error) {
        if (error.response && error.response?.status === 420) {
          let url2 = "/refreshtoken";
          AxiosConfig.get(url2, { headers: authHeaders() })
            .then((res) => {
              window.location.reload();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
        console.log(error);
      });
  };

  // Get List of Categories
  const getCategories = () => {
    let url = `/vouchers/category`;
    AxiosConfig.get(url, { headers: authHeaders() })
      .then((res) => {
        let category = res.data.result;

        setCategories(
          category.map((row) => ({
            label: row.category,
            value: row.id,
          }))
        );
      })
      .catch(function (error) {
        if (error.response && error.response.status === 420) {
          let url2 = "/refreshtoken";
          AxiosConfig.get(url2, { headers: authHeaders() })
            .then((res) => {
              window.location.reload();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
        console.log(error);
      });
  };

  const imageProps = {
    listType: "picture-card",
    onPreview: (file) => handlePreview(file),
    onChange: (file) => handleChange(file),
    onRemove: (file) => {
      const index = imageList.indexOf(file);
      const newFileList = imageList.slice();
      newFileList.splice(index, 1);
      setImageList([]);
    },
    beforeUpload: (file) => beforeUpload(file),
    // {
    //   setImageList([...imageList, file]);
    //   return false;
    // },
    fileList: imageList,
  };

  return (
    <Modal
      visible={props.visible}
      onCancel={props.onCancel}
      width={1380}
      footer={null}
      destroyOnClose
      closable={false}
    >
      <Row
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: 30,
        }}
      >
        <Text style={{ fontSize: 20 }}> Add Reward</Text>
        <CloseCircleOutlined style={{ fontSize: 18 }} onClick={handleCancel} />
        <Divider />
      </Row>
      <Row style={{ marginBottom: 20 }}>
        <Col span={5}>
          <Upload {...imageProps}>
            {imageList.length >= 1 ? null : uploadButton}
          </Upload>
          <Modal
            visible={previewOpen}
            title={"Reward Image"}
            footer={null}
            onCancel={() => setPreviewOpen(false)}
          >
            <img
              alt="example"
              style={{
                width: "100%",
              }}
              src={previewImage}
            />
          </Modal>
        </Col>

        <Col span={18}>
          <Form
            form={form}
            initialValues={{
              is_display: 0,
            }}
            onFinish={handleSubmit}
            labelCol={{
              span: 6,
            }}
          >
            <Row style={{ justifyContent: "space-between" }}>
              <Col>
                <Form.Item
                  label="Reward Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Reward Title is mandatory",
                    },
                  ]}
                  labelAlign="left"
                >
                  <Input style={{ width: 350 }} autoComplete={"off"} />
                </Form.Item>

                <Form.Item label="Validity" name="validity" labelAlign="left">
                  <Input style={{ width: 350 }} autoComplete={"off"} />
                </Form.Item>

                <Form.Item
                  label="Reward Type"
                  name="vouchers_type"
                  rules={[
                    {
                      required: true,
                      message: "Reward Type is mandatory",
                    },
                  ]}
                  labelAlign="left"
                >
                  <Select options={typeOptions} style={{ width: 350 }} />
                </Form.Item>

                <Form.Item
                  label="Segment ID"
                  name="segment_id"
                  labelAlign="left"
                  pattern="[0-9]*"
                  rules={[
                    {
                      message: "Segment ID must be a number",
                      pattern: new RegExp(/^[0-9]+$/),
                    },
                  ]}
                >
                  <Input style={{ width: 350 }} autoComplete={"off"} />
                </Form.Item>

                <Form.Item
                  label="Cap Series ID"
                  name="cap_series_id"
                  labelAlign="left"
                  pattern="[0-9]*"
                  rules={[
                    {
                      required: true,
                      message: "Cap series ID must be a number",
                      pattern: new RegExp(/^[0-9]+$/),
                    },
                  ]}
                >
                  <Input style={{ width: 350 }} autoComplete={"off"} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label="Category"
                  name="voucher_categories"
                  labelAlign="left"
                >
                  <Select
                    showSearch={true}
                    options={categories}
                    tagRender={tagRender}
                    mode="multiple"
                    style={{ width: 350 }}
                    filterOption={(input, option) => {
                      return (
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Active/Inactive"
                  name="is_active"
                  labelAlign="left"
                >
                  <Select style={{ width: 350 }}>
                    <Option value={1}>Active</Option>
                    <Option value={0}>Inactive</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Geographies"
                  name="vouchers_geographies"
                  labelAlign="left"
                >
                  <Select
                    options={geographies}
                    tagRender={tagRender}
                    mode="multiple"
                    style={{ width: 350 }}
                    filterOption={(input, option) => {
                      return (
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  />
                </Form.Item>
                <Form.Item label="Featured" name="featured" labelAlign="left">
                  <Select options={featuredOptions} style={{ width: 350 }} />
                </Form.Item>
                <Form.Item label="Display" name="is_display" labelAlign="left">
                  <Select
                    options={displayOptions}
                    onChange={(item) => setDisplay(item)}
                    style={{ width: 350 }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Radio.Group
              onChange={(e) => setValue(e.target.value)}
              value={value}
              style={{ paddingTop: 20, paddingBottom: 20 }}
            >
              <Radio value={1}>Denominations</Radio>
              <Radio value={2}>Direct Points</Radio>
            </Radio.Group>

            {value === 1 && (
              <Denominations min={null} max={null} denominationData={[]} />
            )}

            {value === 2 && (
              <Form.Item
                label="Points"
                name="points"
                style={{ paddingTop: 20, paddingLeft: 60 }}
                labelCol="left"
              >
                <InputNumber style={{ width: 350 }} autoComplete={"off"} />
              </Form.Item>
            )}
            <Col>
              <Form.Item
                label="Terms and Conditions"
                name="terms_and_conditions"
                labelCol={{ span: 24 }}
              >
                <TextArea rows={6} />
              </Form.Item>
            </Col>

            <Col style={{ justifyContent: "space-between" }}>
              <Form.Item
                style={{ display: "flex", flexDirection: "column" }}
                label="Description"
                name="description"
                labelCol={{ span: 24 }}
              >
                <TextArea rows={6} />
              </Form.Item>
            </Col>

            <Form.Item>
              <Row style={{ justifyContent: "space-evenly", marginTop: 50 }}>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>

                <Button type="primary" onClick={handleCancel}>
                  Cancel
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};
