import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Modal, Row, Select, Tag } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { AxiosConfig } from "../../../APIConfig";
import { authHeaders } from "../../../auth";

function tagRender(props) {
  const { label, closable, onClose } = props;

  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  );
}

export const AddGeographyModal = (props) => {
  const [locations, setLocations] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    const url = "/vouchers/location";

    AxiosConfig.get(url, { headers: authHeaders() })
      .then((res) => {
        if (res.data.status === "success") {
          const location = res.data.result.map((item) => {
            return { label: item.name, value: item.id };
          });
          setLocations(location);
        }
      })
      .catch(function (error) {
        console.log(JSON.stringify(error));
      });
  }, []);

  const geographyOptions = [
    {
      label: "City",
      value: "city",
    },
    {
      label: "State",
      value: "state",
    },
    {
      label: "Country",
      value: "country",
    },
  ];

  const onEditCancel = () => {
    form.resetFields();
    props.onCancel();
  };
  const onEditOk = () => {
    props.onCancel();
  };
  const handleSubmit = (val) => {
    const obj = JSON.parse(JSON.stringify(val));
    if(obj.title!==undefined && obj.locations!==undefined && obj.geography_type!==undefined){
        const url='/vouchers/geography/add'
        AxiosConfig.post(url,obj, { headers: authHeaders() })
        .then((res) => {
          if (res.data.status === "success") {
           console.log('add geography response',res)
           window.location.reload()
          }
        })
        .catch(function (error) {
          console.log(JSON.stringify(error));
        });
    }
  
  };

  return (
    <Modal
      onOk={onEditOk}
      onCancel={onEditCancel}
      visible={props.visible}
      closable={false}
      maskClosable={false}
      footer={null}
    >
      <Row style={{ justifyContent: "space-between" }}>
        <Text style={{ fontFamily: "sans-serif", fontSize: 20 }}>
          Add Geography
        </Text>
        <CloseCircleOutlined style={{ fontSize: 18 }} onClick={onEditCancel} />
      </Row>
      <Divider />
      <Form form={form} layout="horizontal" onFinish={handleSubmit}>
        <Form.Item name="title" label="Title">
          <Input autoComplete="off" style={{ width: 350, left: 78 }} />
        </Form.Item>

        <Form.Item name="geography_type" label="Geography Type">
          <Select options={geographyOptions} />
        </Form.Item>

        <Form.Item label="Locations" name="locations">
          <Select
            options={locations}
            tagRender={tagRender}
            mode="multiple"
            style={{ width: 350, left: 43 }}
          />
        </Form.Item>

        <Form.Item>
          <Row style={{ justifyContent: "space-evenly" }}>
            <Button type="primary" htmlType="submit">
              SAVE
            </Button>

            <Button type="primary" onClick={onEditCancel}>
              Cancel
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};
