
const appKey=process.env.REACT_APP_BIRA_APPKEY
const appSecret=process.env.REACT_APP_BIRA_APP_SECRET
//export const cloudFrontUrl= process.env.CLOUD_FRONT_URL 

// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem("token") || null;
};

export const getAdmin = () => {
  return localStorage.getItem("admin") || null;
};

export const getrnum = () => {
  return sessionStorage.getItem("rnum") || null;
};

export const getstore = () => {
  return JSON.parse(sessionStorage.getItem("store")) || null;
};

export const getpage = () => {
  return sessionStorage.getItem("page") || null;
};

export const getdetail = () => {
  return sessionStorage.getItem("detail") || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem("token");
};

// set the token and user from the session storage
export const setUserSession = (token) => {
  sessionStorage.setItem("token", token);
};

export const setAdmin = (admin) => {
  localStorage.setItem("admin", admin);
};

export const setrnum = (rnum) => {
  sessionStorage.setItem("rnum", rnum);
};

export const setdetail = (detail) => {
  sessionStorage.setItem("detail", detail);
};

export const setstore = (data) => {
  sessionStorage.setItem("store", JSON.stringify(data));
};

export const setpage = (page) => {
  sessionStorage.setItem("page", page);
};

//line item/product for edit modal
export const setLineItem = (item) => {
  sessionStorage.setItem("lineItem",JSON.stringify(item));
};

export const getLineItem = () => {
  return sessionStorage.getItem("lineItem") || null;
};

export const getIsLoggedIn= () => {
  return sessionStorage.getItem("isLoggedIn") || null;
};

export const setIsLoggedIn = (val) => {
  sessionStorage.setItem("isLoggedIn", val);
};


export const getDenominations=()=>{
  return sessionStorage.getItem("denominations") || null;
}

export const setDenominationStore = (val) => {
  sessionStorage.setItem("denominations", JSON.stringify(val));
};


export const getSaveButtonClicked=()=>{
  return sessionStorage.getItem("saveButton") || null;
}

export const setSaveButtonClicked = () => {
  sessionStorage.setItem("saveButton", true);
};

export const removeSaveButtonClicked = () => {
  sessionStorage.removeItem("saveButton");
};

//headers 
export const headers = {
  "app-key": appKey,
  "app-secret": appSecret,
};

export const authHeaders = () => {
  return {
    "app-key": appKey,
    "app-secret": appSecret,
    "auth-token": sessionStorage.getItem("token") || null,
  };
};
