import { Card, Col, Row,  Button } from "antd";
import moment from "moment";
import React from "react";

export const CustomerDetails = (props) => {
  const { userdetail, viewHistory } = props;

  return (
    <Card
      extra={<Button onClick={viewHistory}>View History</Button>}
      title="Customer Details"
      bordered={true}
      style={{ borderColor: "gray" }}
    >
      <Row
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Col style={{ marginBottom: 10 }}> Name:</Col>
        <Col>
          {" "}
          {userdetail.firstname
            ? `${userdetail.firstname} ${
                userdetail.lastname ? userdetail.lastname : ""
              }`
            : "---"}
        </Col>
      </Row>

      <Row
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Col style={{ marginBottom: 10 }}> UserId:</Col>
        <Col>{userdetail.user_id ? userdetail.user_id : "---"}</Col>
      </Row>

      <Row
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Col style={{ marginBottom: 10 }}> Age:</Col>
        <Col>{userdetail.dob && userdetail.dob!=='NA' && userdetail.dob  ? moment().format('YYYY')-moment(userdetail.dob.substring(0,10)).format('YYYY') : "---"}</Col>
      </Row>

      <Row
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Col style={{ marginBottom: 10 }}> State:</Col>
        <Col>{userdetail.state ? userdetail.state : "---"}</Col>
      </Row>

      <Row
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Col style={{ marginBottom: 10 }}>Lifetime Points:</Col>
        <Col>
          {userdetail.lifetime_points ? userdetail.lifetime_points : "---"}
        </Col>
      </Row>

      <Row
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Col style={{ marginBottom: 10 }}>Current Points:</Col>
        <Col>
          {userdetail.loyalty_points ? userdetail.loyalty_points : "---"}
        </Col>
      </Row>

      <Row
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Col style={{ marginBottom: 10 }}>Membership Slab:</Col>
        <Col>{userdetail.current_slab ? userdetail.current_slab : "---"}</Col>
      </Row>
    </Card>
  );
};
