import Base from "../Base";
import { useHistory,  useParams } from "react-router-dom";
import {
  authHeaders,
  getrnum,
  getToken,
  getpage,
  setUserSession,
  setrnum,
} from "../../auth";
import { useState } from "react";
import { AxiosConfig } from "../../APIConfig";
import { useEffect } from "react";
import {  Col, Divider, Row, Spin, Typography } from "antd";

import { CustomerDetails } from "./RecieptDetails/CustomerDetails";
import { RecieptContents } from "./RecieptDetails/RecieptContents";
import { RecieptImage } from "./RecieptDetails/RecieptImage";
import { ItemTable } from "./RecieptDetails/ItemTable";
import { ArrowLeftOutlined } from "@ant-design/icons";


const ReceiptDetail = (props) => {
  const menuKey = localStorage.getItem("menuKey");
  const { Title, Text } = Typography;
  const [userdetail, setUserdetail] = useState({});
  const [storedata, setStoredata] = useState({});
  const [prepage] = useState(getpage());
  const [status, setStatus] = useState(0);
  const [ocstat, setocstat] = useState(false);

  const [, setload] = useState(true);

  const [loading, setloading] = useState(true);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (!isNaN(parseInt(id))) {
      setrnum(parseInt(id));
    }
  }, [id]);

  const viewHistory = () => {
    console.log("view history is called");
    history.push({
      pathname: "/viewhistory",
      mobile:
        userdetail.mobile && userdetail.mobile !== undefined
          ? userdetail.mobile
          : 0,
    });
  };

  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    setTimeout(() => setloading(false), 2500);
    var token = getToken();
    var rnum = getrnum();

    let url1 = `/receipts/customer_deatils?receipt_id=${rnum}`;
    AxiosConfig.get(url1, { headers: authHeaders() })
      .then((res) => {
        if (res.data.result.code === 200) {
          setUserdetail(res.data.result.reponse);
          setload(false);
        } else {
          setload(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setload(false);
      });
    let url2 = `/receipts?receipt_id=${rnum}&limit=-1&offset=-1`;
    AxiosConfig.get(url2, { headers: authHeaders() })
      .then((res) => {
        setStoredata(res.data.result.data[0]);
        setStatus(res.data.result.data[0].status_id); //to be sent to Award or Reject
        console.log("data", res.data.result.data[0].is_flagged_open);
        setocstat(res.data.result.data[0].is_flagged_open);
      })

      .catch(function (error) {
        if (error.response.status === 420) {
          let url2 = "/refreshtoken";
          let header1 = {
            "app-key": `Bira_admin`,
            "app-secret": `sk_test_4eC39HqLyjWDarjtT1zdp7dc`,
          };
          let body = { token: token };
          AxiosConfig.post(url2, body, { headers: header1 })
            .then((res) => {
              setUserSession(res.data.result.token);
              window.location.reload();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
        console.log(error);
      });
  }, [prepage]);

  return (
    <Base menuKey={menuKey}>
      {loading && (
        <Row
          style={{
            height: 500,
            width: 1200,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin spinning={loading} delay={500} />
        </Row>
      )}
      {!loading && (
        <Col>
          <Col span={4} style={{ marginTop: 20 }}>
            <Row style={{ justifyContent: "space-around" }}>
              <ArrowLeftOutlined
                onClick={goBack}
                style={{ fontSize: 20, marginTop: 5 }}
              />
              <Title level={3}>
                <Text type="secondary">Bill Scan</Text>
              </Title>
            </Row>
          </Col>

          <Col span={24} style={{ paddingTop: 20, paddingBottom: 50 }}>
            {/* Section 1: Customer Details Box and Reciept Details Box */}
            <Row
              style={{
                flexDirection: "row",
              }}
            >
              <Col span={7} style={{ paddingLeft: 20 }}>
                <CustomerDetails
                  userdetail={userdetail}
                  viewHistory={viewHistory}
                />
              </Col>

              <Col span={16} style={{ paddingLeft: 20 }}>
                <RecieptContents contents={storedata} />
                <Divider />
              </Col>
            </Row>
            {/* Section 2: Receipt Image and Line Items */}
            <Row>
              <Col span={7} style={{ paddingLeft: 20 }}>
                <RecieptImage data={storedata} />
              </Col>
              <Col span={16} style={{ paddingLeft: 20 }}>
                {/* Line Items*/}
                <ItemTable ocstat={ocstat} status={status} />
              </Col>
            </Row>
          </Col>
        </Col>
      )}
    </Base>
  );
};

export default ReceiptDetail;
