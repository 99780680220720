/* eslint-disable react-hooks/exhaustive-deps */
import { EditOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Spin, Table, Tag, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { AxiosConfig } from "../../APIConfig";
import { authHeaders, setUserSession, setpage } from "../../auth";
import Base from "../Base";
import { EditRewardModal } from "./EditRewardModal";
import { AddRewardModal } from "./AddRewardModal";
toast.configure();

export const Rewards = () => {
  const [loading, setLoading] = useState(false);
  const [rewards, setRewards] = useState([]);
  const { Title, Text } = Typography;
  const [editContent, setEditContent] = useState({});
  const [editVisible, setEditVisible] = useState(false);
  const [denominations, setDenominations] = useState([]);

  const [filterData, setFilterData] = useState([]);
  const [nameSearch, setNameSearch] = useState("");
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [editRadioVal, setEditRadioVal] = useState(0);

  const getFlaggedReceipt = () => {
    setLoading(true);
    AxiosConfig.get(`/rewards`, {
      headers: authHeaders(),
    })

      .then((res) => {
        if (res.data.status === "success") {
          // console.log("rewards -> ", res.data.result.data);
          setRewards(res.data.result.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res?.data?.data?.count
              ? res?.data?.data?.count
              : res?.data?.result?.pagination?.total_count,
          },
        });
      })
      .catch((error) => {
        if (error?.response?.status === 420) {
          let url2 = "/refreshtoken";

          AxiosConfig.post(url2, { headers: authHeaders() })
            .then((res) => {
              setUserSession(res.data.result.token);
              window.location.reload();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
        console.log(error);
      });
  };

  useEffect(() => {
    setpage("/rewards");
    getFlaggedReceipt();
  }, [JSON.stringify(tableParams)]);

  useEffect(() => {
    if (rewards && rewards?.length > 0) {
      const results = rewards.filter(
        (item) =>
          (item?.title &&
            item.title?.toLowerCase().includes(nameSearch.toLowerCase())) ||
          (item?.vouchers_type &&
            item?.vouchers_type?.voucher_type &&
            item.vouchers_type.voucher_type
              ?.toLowerCase()
              .includes(nameSearch.toLowerCase()))
      );
      setFilterData(results);
    }
  }, [nameSearch, rewards]);

  const onEditCancel = () => {
    setEditVisible(false);
  };

  const importRewards = () => {
    const url = "/import/rewards/qc";
    AxiosConfig.post(url, null, { headers: authHeaders() })
      .then((res) => {
        toast(JSON.stringify(res.data.result));

        setTimeout(() => {
          window.location.reload();
        }, 4000);
      })
      .catch(function (error) {
        console.log(`${JSON.stringify(error.response)}`);
        toast(JSON.stringify(error.response));
      });
  };

  const addRewards = () => {
    setAddModalVisible(true);
  };

  const onAddCancel = () => {
    setAddModalVisible(false);
  };

  const columns = [
    {
      title: "Reward Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Denomination",
      dataIndex: ["denomination_min", "denomination_max"],
      render: (i, item) => (
        <Text>
          {item?.denomination_min && item?.denomination_max
            ? `${
                JSON.stringify(item.denomination_min) +
                "-" +
                JSON.stringify(item.denomination_max)
              }`
            : "-"}
        </Text>
      ),
    },
    {
      title: "Points",
      dataIndex: "points",
      key: "points",
      render: (item) => <Text>{item ? item : "-"}</Text>,
    },
    {
      title: "Validity",
      dataIndex: "validity",
      key: "validity",
    },
    {
      title: "Category",
      dataIndex: "vouchers_categories",
      key: "vouchers_categories",
      render: (tags) => (
        <>
          {tags.map((tag) => {
            let color = "geekblue";
            return (
              <Tag color={color} key={tag.category}>
                {tag.category}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (item) => <Text>{item === 1 ? "Active" : "Inactive"}</Text>,
    },
    {
      title: "Display",
      dataIndex: "is_display",
      key: "is_display",
      render: (item) => <Text>{item ? "Yes" : "No"}</Text>,
    },
    {
      title: "Type",
      dataIndex: "vouchers_type",
      render: (item) => (
        <Text>{item?.voucher_type ? `${item.voucher_type}` : ""}</Text>
      ),
    },
    {
      title: "QC Status",
      dataIndex: "qc_status",
      key: "qc_status",
      filters: [
        {
          text: "active",
          value: "active",
        },
        {
          text: "updated",
          value: "updated",
        },
        {
          text: "deleted",
          value: "deleted",
        },
        {
          text: "new",
          value: "new",
        },
      ],
      onFilter: (value, record) => record.qc_status.indexOf(value) === 0,
    },
    {
      title: " ",
      dataIndex: "operation",
      key: "operation",
      width: "10%",
      render: (text, record) => {
        return (
          <Row style={{ justifyContent: "space-evenly" }}>
            <EditOutlined
              onClick={() => {
                setEditContent(record);
                setEditVisible(true);

                let radioBtnValue = 0;
                if (
                  record?.reward_denominations &&
                  record.reward_denominations?.length > 0
                ) {
                  radioBtnValue = 1;
                } else if (
                  record?.points &&
                  record.points !== null &&
                  record.points !== 0
                ) {
                  radioBtnValue = 2;
                }
                setEditRadioVal(radioBtnValue);

                let denominationList = record.reward_denominations.map(
                  (row) => {
                    return {
                      id: row.id,
                      denomination: row.denomination,
                      multiplier: row.multiplier === null ? 0 : row.multiplier,
                      currency: row.currency,
                      points: row.points,
                      currency_numeric_code: row.currency_numeric_code,
                      reward_id: row.reward_id,
                    };
                  }
                );
                setDenominations(denominationList);
                console.log("record edit", record);
              }}
            />
          </Row>
        );
      },
    },
  ];

  const handleTableChange = (_pagination) => {};

  return (
    <Base menuKey="7-2">
      {loading && (
        <Row
          style={{
            height: 500,
            width: 1200,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin spinning={loading} delay={500} />
        </Row>
      )}
      {!loading && (
        <Col style={{ marginBottom: 20 }}>
          <Col span={3} style={{ marginTop: 20 }}>
            <Row style={{ justifyContent: "space-around" }}>
              <Title level={3}>
                <Text type="secondary">Rewards</Text>
              </Title>
            </Row>
          </Col>
          <Row
            name="row2"
            style={{
              justifyContent: "space-between",
              paddingRight: 25,
              marginBottom: 10,
            }}
          >
            <Col span={10} style={{ paddingLeft: 25 }}>
              <Input
                placeholder={"Search Title / Type"}
                onChange={(event) => {
                  // search(event.target.value);
                  setNameSearch(event.target.value);
                }}
              />
            </Col>
            <Col>
              <Button
                style={{ width: 100, marginRight: 20 }}
                onClick={addRewards}
              >
                Add{" "}
              </Button>
              <Button
                style={{ width: 100 }}
                onClick={() => {
                  importRewards();
                }}
              >
                Import{" "}
              </Button>
            </Col>
          </Row>

          <Row
            style={{
              justifyContent: "center",
              paddingTop: 20,
            }}
          >
            <Col
              span={23}
              style={{
                justifyContent: "flex-start",
              }}
            >
              <EditRewardModal
                visible={editVisible}
                onCancel={onEditCancel}
                content={editContent}
                denominationData={denominations}
                editRadioVal={editRadioVal}
                setEditRadioVal={setEditRadioVal}
              />
              <AddRewardModal
                visible={addModalVisible}
                onCancel={onAddCancel}
              />
              <Table
                columns={columns}
                dataSource={filterData}
                onChange={handleTableChange}
                setTableParams={setTableParams}
              />
            </Col>
          </Row>
        </Col>
      )}
    </Base>
  );
};
