import {  getAdmin, getToken } from "../../auth";

export const isAuthenticated = () => {
   if (getToken()) {
       if(getAdmin()){
        return true;
       }
     
      
    }
    return false;
};
