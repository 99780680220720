import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "antd/lib/modal/Modal";
import { authHeaders } from "../../auth";
import { AxiosConfig } from "../../APIConfig";
require("../../styles/Base.scss");

const ReportsModal = ({ showModal, handleCancel, reportType }) => {
  //console.log("inside reports modal");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  //const [disableDownload, setDisableDownload] = useState(true);

  
  const handleDownloadBillScanReport = () => {
    const url = "/report/receipt";
    const params = {
      submission_start_date: fromDate,
      submission_end_date: toDate,
    };
    AxiosConfig.get(url, { headers: authHeaders(), params })
      .then((res) => {
        const { data } = res;
        console.log("responce===>", res);

        let formattedData = "";
        if (data && data?.result.length > 0) {
          formattedData +=
            "User's mobile number,Date of submission,Outlet name,Bill Status,Points earned, Invoice Date\n";
          data?.result.forEach((row) => {
            const mobileNumber = row.mobile_number || "NA";
            const dateOfSubmission = row.created_at || "NA";
            const outletName = row.store_details?.store_name || "NA";
            const billStatus = row.processing_master_status?.status || "NA";
            const points = row.points_awarded || "NA";
            const invoiceDate = row.receipt_txn_date || "NA";

            formattedData += `${mobileNumber},${dateOfSubmission},${outletName},${billStatus},${points},${invoiceDate}\n`;
          });
        }

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(new Blob([formattedData]));
        downloadLink.setAttribute("download", "BillScanReport.csv");
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      })
      .catch((error) => {
        console.log("error ::", error);
      });
  };

  const handleConfirm = () => {
    if (!fromDate || !toDate) {
      setErrorMessage("Please choose both From Date and To Date");
      return;
    }

    if (fromDate > toDate) {
      setErrorMessage("From Date cannot be greater than To Date");
      return;
    }

    if (reportType === "bill_scan") {
      handleDownloadBillScanReport();
    }
    // else if (reportType === "beer_rating") {
    //   handleDownloadBeerRatingReport();
    // }
    handleCancel();
  };

  // const handleCancel = () => {
  //   setShowModal(false);
  // };

  const handleFromDateChange = (e) => {
    const selectedFromDate = e.target.value;
    const selectedToDate = toDate;

    if (selectedToDate && selectedFromDate > selectedToDate) {
      setErrorMessage("From Date cannot be greater than To Date");
    } else {
      setErrorMessage("");
    }

    setFromDate(selectedFromDate);
    // setDisableDownload(!selectedFromDate || !selectedToDate);
  };

  const handleToDateChange = (e) => {
    const selectedFromDate = fromDate;
    const selectedToDate = e.target.value;

    if (selectedFromDate && selectedFromDate > selectedToDate) {
      setErrorMessage("From Date cannot be greater than To Date");
    } else {
      setErrorMessage("");
    }

    setToDate(selectedToDate);
    // setDisableDownload(!selectedFromDate || !selectedToDate);
  };

  return (
    <Modal
      visible={showModal}
      onCancel={handleCancel}
      title="Download Report"
      footer={null}
      //modalWidth={600}
      className="custom-modal"
    >
      {errorMessage && (
        <div style={{ color: "red", marginBottom: 20 }}>{errorMessage}</div>
      )}

      <div>
        <label style={{ fontFamily: "sans-serif", fontSize: 16 }}>
          From Date :{" "}
        </label>
        <input
          type="date"
          value={fromDate}
          onChange={handleFromDateChange}
          max={new Date().toISOString().split("T")[0]}
        />
      </div>

      <div style={{ marginTop: 20 }}>
        <label style={{ fontFamily: "sans-serif", fontSize: 16 }}>
          To Date :{" "}
        </label>
        <input
          type="date"
          value={toDate}
          onChange={handleToDateChange}
          max={new Date().toISOString().split("T")[0]}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "center",
          marginTop: 40,
        }}
      >
        <button
          style={{
            fontSize: 14,
            fontWeight: "bold",
            backgroundColor: "black",
            color: "white",
            height: 35,
            width: 100,
            borderWidth: 1,
            borderRadius: 5,
            cursor: "pointer",
          }}
          onClick={handleConfirm}
          //disabled={disableDownload}
        >
          Download
        </button>

        <button
          style={{
            fontSize: 14,
            fontWeight: "bold",
            backgroundColor: "red",
            color: "white",
            height: 35,
            width: 100,
            borderWidth: 1,
            borderColor: "red",
            borderRadius: 5,
            cursor: "pointer",
          }}
          onClick={handleCancel}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default ReportsModal;
