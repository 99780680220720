import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
} from "antd";
import React, { useEffect } from "react";
import { authHeaders } from "../../../auth";
import { AxiosConfig } from "../../../APIConfig";
import { useState } from "react";
import Text from "antd/lib/typography/Text";
import { CloseCircleOutlined } from "@ant-design/icons";


//Modal to Edit Items in the Line List
export const EditItem = (props) => {
  const [formValues, setFormValues] = useState({});

  const [productId, setProductId] = useState({});
  const [packTypeId, setPackTypeId] = useState();
  const [productName, setProductName] = useState();
  const [packTypeDescription, setPacktypeDescription] = useState("");
  const [displayMessage,setDisplayMessage]=useState(false)

  useEffect(() => {
    setFormValues(props.content);
    setProductId(props.content.product_id);
    setProductName(props.content.product_name);
    setPackTypeId(props.content.pack_type_id);
    setPacktypeDescription(props.content.pack_type_description);
  }, [props.content]);

  const [, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [packTypes, setPackTypes] = useState([]);
  const [form] = Form.useForm();

  form.setFieldsValue({
    pack_type_id: packTypeId,
    quantity: formValues.quantity,
    aliases: formValues.aliases,
    product_id: productName,
    amount: formValues.amount,
  });

  console.log("products", products);
  console.log("form Values", formValues);
  console.log("pack types", packTypes);

  const handleSubmit = (val) => {
    let obj = val;
    obj.product_id = productId;
    obj.previous_id = props.content.product_id;
    obj.product_name = productName;
    obj.pack_type_description = packTypeDescription;
   //update the row
    props.onOk(obj)
    console.log("submit value", obj);
  };

  const handleCancel = () => {
    form.resetFields();
    setDisplayMessage(false)
    props.onCancel();
  };

  //fetch all products for the product name Selector
  useEffect(() => {
    let url1 = `/products`;
    setLoading(true);
    AxiosConfig.get(url1, { headers: authHeaders() })
      .then((res) => {
        if (res.data.status === "success") {
          console.log('products aliases check',res.data.result)
          let prods = res.data.result.map((item) => {
           
            return { label: item.product_name, value: item.product_id };
          });
          setProducts(prods);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
    let url2 = "/packTypes?product_id=" + props.content.product_id;
    AxiosConfig.get(url2, { headers: authHeaders() })
      .then((res) => {
        if (res.data.status === "success") {
          let packs = res.data.result.map((item) => {
            return {
              value: item.pack_type_id,
              label: item.pack_type_description,
            };
          });
        
          setPackTypes(packs);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }, [props.content.product_id]);

  //upon clicking ok in the modal after editing the item
  const onEditItemOk = () => {
    props.onOk();
    setDisplayMessage(false)
  };

  const onPackTypeSelection = (key, val) => {
    setPackTypeId(key);
    setDisplayMessage(false)
    setPacktypeDescription(val.label);
  };

  const onProductSelection = (key, val) => {
    // console.log('Key val',key,val)
    //changing the packet options after a different product is selected
    setProductId(key);
    setDisplayMessage(true)
    setProductName(val.label);
    let url1 = "/packTypes?product_id=" + key;
    AxiosConfig.get(url1, { headers: authHeaders() })
      .then((res) => {
        if (res.data.status === "success") {
          let packs = res.data.result.map((item) => {
            return {
              value: item.pack_type_id,
              label: item.pack_type_description,
            };
          });
          setPackTypes(packs);
          console.log("inside pack selection", packs);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <Modal
      onCancel={props.onCancel}
      visible={props.visible}
      onOk={onEditItemOk}
      maskClosable={false}
      footer={null}
      closable={false}
    >
      <Col style={{ paddingRight: 10, paddingTop: 20 }}>
        <Row
          style={{ flexDirection: "column", justifyContent: "space-between" }}
        >
          <Row style={{ justifyContent: "space-between" }}>
            <Text style={{ fontFamily: "sans-serif", fontSize: 20 }}>
              Edit Line Item
            </Text>
            <CloseCircleOutlined
              style={{ fontSize: 18 }}
              onClick={handleCancel}
            />
          </Row>
          <Divider />
          <Form form={form} onFinish={handleSubmit}>
            <Form.Item label="Brand Name" name="product_id">
              <Select
                options={products}
                style={{ width: 300, left: 20 }}
                onChange={(e, v) => {
                  onProductSelection(e, v);
                }}
              />
            </Form.Item>
            <Col style={{color:'red',left:115,marginBottom:displayMessage?30:0}}>
            {
                  displayMessage && <Text style={{color:'red' ,fontSize:13}}>
                    Please pick the Pack Type for the producted chosen
                  </Text>
                }
            
            </Col>

               
            <Form.Item name="pack_type_id" label="Pack Types">
              <Select
              placeholder={'Please select the product first'}
                style={{ width: 300, left: 25 }}
                options={packTypes}
                onChange={(e, v) => {
                  onPackTypeSelection(e, v);
                }}
              />
            </Form.Item>

            <Form.Item name="quantity" label="Quantity">
              <InputNumber style={{ width: 300, left: 45 }} />
            </Form.Item>

            <Form.Item name="aliases" label="Aliases">
              <Input style={{ width: 300, left: 52 }} />
            </Form.Item>

            <Form.Item name="amount" label="Amount">
              <InputNumber style={{ width: 300, left: 50 }} />
            </Form.Item>

            <Form.Item>
              <Row style={{ justifyContent: "space-evenly", marginTop: 50 }}>
                <Button type="primary" htmlType="submit">
                  SAVE
                </Button>

                <Button type="primary" onClick={handleCancel}>
                  Cancel
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Row>
      </Col>
    </Modal>
  );
};