/* eslint-disable react-hooks/exhaustive-deps */
import Base from "../Base";
import { Row, Typography } from "antd";
import { authHeaders, setpage } from "../../auth";
import { AxiosConfig } from "../../APIConfig";
import { useEffect, useState } from "react";
import ReceiptTable from "./ReceiptTable";
import moment from "moment";

const Pending = () => {
  const { Title, Text } = Typography;
  const [pendingData, setPendingData] = useState([]);

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [loading, setLoading] = useState(false);

  const getFlaggedReceipt = () => {
    setLoading(true);
    const offsetVal =
      tableParams.pagination.current * tableParams.pagination.pageSize -
      tableParams.pagination.pageSize;

    const limitVal = tableParams.pagination.pageSize;
    // let offsetVal =
    //   (tableParams.pagination.current - 1) * tableParams.pagination.pageSize;

    // let limitVal = tableParams.pagination.pageSize;

    AxiosConfig.get(`/receipts/pending?offset=${offsetVal}&limit=${limitVal}`, {
      headers: authHeaders(),
    })
      .then((res) => {
        const temp = res.data.data.map((v) => {
          return {
            ...v,
            receipt_number: v.receipt_number ?? "-",
            created_at: moment(v.created_at).format("DD-MM-YYYY"),
            customer_external_id: v.customer_external_id ?? "-",
            receipt_txn_date: !v.receipt_txn_date
              ? "No Date"
              : v.receipt_txn_date,
            ocr_confident_level: v.ocr_confident_level ?? 0,
            remarks: v.remarks ?? "-",
          };
        });
        setPendingData(temp);
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.pagination.total_count,
          },
        });
      })
      .catch((error) => console.log("error =>", error, "\n", error?.response));
  };

  useEffect(() => {
    setpage("/pendingreceipts");
    getFlaggedReceipt();
  }, [JSON.stringify(tableParams)]);

  return (
    <Base menuKey="2">
      <Row style={{ marginTop: 20 }}>
        <Title level={3}>
          <Text type="secondary">Pending Receipts</Text>
        </Title>
      </Row>

      <ReceiptTable
        data={pendingData}
        tableParams={tableParams}
        setTableParams={setTableParams}
        loading={loading}
      />
    </Base>
  );
};

export default Pending;
