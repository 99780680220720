import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { Typography, Input, Row, Col, Button } from "antd";
import logo from "../assets/logo.png";
import {
  UserOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
  LockOutlined,
} from "@ant-design/icons";
import Layout from "antd/lib/layout/layout";
import { Fragment } from "react";
import { Formik } from "formik";
import * as yup from "yup";


import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../slices/auth";
import md5 from "md5";
import { getToken } from "../auth";
require("../styles/Login.scss");

const Home = (props) => {
  const history = useHistory();
  const [errorMsg, setErrorMsg] = useState();
  const { Title, Text } = Typography;
  const validationSchema = yup.object({
    username: yup.string().email().required(),
    password: yup.string().min(8).required(),
  });
  const {isLoggedIn} = useSelector((state) => state.auth);


  useEffect(() => {
    if (getToken()) {
      alert("You need to logout to exit");
      history.push("/flagged");
    }
    const handleKeyDown = ({ key }) => {
      if (key === "Enter") {
        this.login();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [history])

  const dispatch=useDispatch()
  return (
    <Fragment>
      {isLoggedIn  ? (
        history.push("/flagged")
      ) : (
        <div className="login-wrapper">
          <Layout style={{ height: "100vh" }}>
            <Row
              justify="space-around"
              align="middle"
              style={{ height: "100vh", marginRight: "0px" }}
            >
              <Col xs={24} md={12} lg={12}>
                <div className="login-container">
                  <div>
                    <img src={logo} alt="logo" className="logos mb-1" />
                    <Title level={4}>
                      <Text type="secondary">Please login to proceed</Text>
                    </Title>
                    <Text type="danger">{errorMsg}</Text>
                    <Formik
                      initialValues={{
                        username: "",
                        password: "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values) => {
                        const user_name = values["username"];
                        const hash_password = md5(values["password"]);

                        dispatch(userLogin({ user_name, hash_password }))
                          .unwrap()
                          .then(() => {
                            history.push("/flagged");
                          })
                          .catch((err) => {
                            console.log(err);
                            setErrorMsg(
                              "Provide valid credentials and try again"
                            );
                          });
                      }}
                    >
                      {({ handleSubmit, handleChange, values, errors }) => (
                        <form onSubmit={handleSubmit}>
                          <Input
                            className="mb-1"
                            name="username"
                            value={values.username}
                            placeholder="Enter your Email"
                            prefix={
                              <UserOutlined className="site-form-item-icon" />
                            }
                            onChange={handleChange}
                          />
                          <p className="display_error">{errors.username}</p>
                          <Input.Password
                            className="mb-1"
                            name="password"
                            value={values.password}
                            placeholder="Input password"
                            prefix={
                              <LockOutlined className="site-form-item-icon" />
                            }
                            iconRender={(visible) =>
                              visible ? (
                                <EyeTwoTone />
                              ) : (
                                <EyeInvisibleOutlined />
                              )
                            }
                            onChange={handleChange}
                          />
                          <p className="display_error">{errors.password}</p>
                          <Button className="ant-btn-bira" htmlType="submit">
                            Login
                          </Button>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </Col>
            </Row>
          </Layout>
        </div>
      )}
    </Fragment>
  );
};

export default Home;
